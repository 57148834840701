import { createAsyncThunk } from "@reduxjs/toolkit";
import { emailVerification, emailVerificationResent, forgotPassword, forgotPasswordReset, login, signup } from "../../helpers/backend_helper";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../helpers/api_helper";

export const loginThunk = createAsyncThunk(
  "login/loginThunk",
  async (data: any) => {
    try {
      const { values, navigate, notVerifiy } = data;
      const response = await login(values);
      const encodeduserEmail = btoa(values?.email?.toLowerCase());
      localStorage.setItem("signup-site", encodeduserEmail);
      if (response?.status?.toLowerCase() === "success") {
        localStorage.setItem("auth-user", response?.access_token);
        setToken();
        toast.success(response?.message);
        navigate(response?.access_token);
        localStorage.removeItem('signup-site');
      } else {
        if (response?.isOtpVerified?.toLowerCase() === "false") {
          localStorage.setItem("signup-user", response?.userid);
          notVerifiy();
        }
        toast.error(response?.message);
      }
      return await response;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      console.error("An error occurred in loginThunk:", error);
    }
  }
);

//Add New User
export const signupThunk = createAsyncThunk(
  "signup/signupThunk",
  async (data: any) => {
    const { values, navigate } = data;
    const response = await signup(values);

    if (response?.status?.toLowerCase() === "success") {
      localStorage.setItem("signup-user", response?.userId);
      const encodeduserEmail = btoa(values?.email?.toLowerCase());
      localStorage.setItem("signup-site", encodeduserEmail);
      localStorage.setItem("signup-user", response?.userId);
      toast.success(response?.message);
      navigate();
    } else {
      toast.error(response?.message);
    }
    return await response;
  }
);

//User verification
export const emailVerifiyThunk = createAsyncThunk(
  "email/emailVerifiyThunk",
  async (data: any) => {
    const userId = localStorage.getItem('signup-user')
    const { value, navigate } = data;
    const verifiyData: any = {};
    verifiyData['verificationToken'] = Number(value?.verificationToken)
    verifiyData['id'] = userId
    const response = await emailVerification(verifiyData);
    if (response?.status?.toLowerCase() === "success") {
      // localStorage.removeItem('signup-site');
      localStorage.removeItem('signup-user');
      toast.success(response?.message);
      navigate();
    } else {
      toast.error(response?.message);
    }
    return await response;
  }
);

//user Email verification Resent
export const resentVerifyThunk = createAsyncThunk(
  "resent/resentVerifyThunk",
  async (data: any) => {
    const { values, navigate } = data;
    const response = await emailVerificationResent(values);
    if (response?.status?.toLowerCase() === "success") {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
    return await response;
  }
);

//Forgot Password
export const forgotPswThunk = createAsyncThunk(
  "forgotPsw/forgotPswThunk",
  async (data: any) => {
    const { values, navigate } = data;
    const response = await forgotPassword(values);
    if (response?.status?.toLowerCase() === "success") {
      const encodedEmail = btoa(values?.email?.toLowerCase());
      localStorage.setItem("reg-site", encodedEmail);
      toast.success(response?.message);
      navigate();
    } else {
      toast.error(response?.message);
    }
    return await response;
  }
);

//Reset Password
export const resetPswThunk = createAsyncThunk(
  "resetPsw/resetPswThunk",
  async (data: any) => {
    const { values, navigate } = data;
    const resetVal: any = {};
    resetVal['email'] = values?.email;
    resetVal['password'] = values?.password;
    resetVal['confPwd'] = values?.confPwd;
    resetVal['verificationToken'] = Number(values?.verificationToken);
    const response = await forgotPasswordReset(resetVal);
    if (response?.status?.toLowerCase() === "success") {
      localStorage.removeItem('reg-site');
      toast.success(response?.message);
      navigate();
    } else {
      toast.error(response?.message);
    }
    return await response;
  }
);
