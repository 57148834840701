import React from 'react';
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";


interface ReviewModalProps {
    showModal: boolean;
    toggle: () => void;
    modalTitle?: string;
    description?: string;
    primaryBtn?: string;
    customStyles?: string;
}
const ReviewModal = ({ showModal, toggle, modalTitle, description, customStyles }: ReviewModalProps) => {

    return (
        <div>
            {showModal && (
                <div className={`custom-modal-overlay `}>
                    <div className={`custom-modal ${customStyles}`}>
                        <div className="modal-header">
                            <h1>{modalTitle}</h1>
                            {/* <span onClick={toggle} className='close-icon'>&times;</span> */}
                        </div>
                        <div className="modal-body">
                            <div className='text-center'>
                                {typeof description === 'string' ? <p>{description}</p> : description}
                            </div>
                            <div className='filter-btn d-flex justify-content-center'>
                                {<PrimaryButton btnName={"Ok"} showIcon={false} onClick={toggle} />}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewModal;
