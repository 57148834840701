import cash from '../../assets/images/svgicons/cash.svg';
import stockOption from '../../assets/images/svgicons/stock-option.svg';
import Delivery from '../../assets/images/svgicons/delivery.svg';
import Index from '../../assets/images/svgicons/index.svg';
import Futures from '../../assets/images/svgicons/stock-index-1.svg';

export const EquityItems = {
    title: "Equity",
    description: "Investing in stocks offers the opportunity for significant long-term returns, contributing to the expansion of your wealth. To venture into the stock market, you can set up a Demat account through a reputable brokerage firm. Engage in equity trading and investment to access the ideas shared by successful traders amongst the trading community.",
    descriptionTwo: "Explore a world of opportunities with our specialized Equity Services. At Fideal, we offer a range of services tailored to meet the diverse needs of equity traders and investors. The portal connects the Successful traders and the newcomers or else unable to get the way of successful trades.",

    explanation: [
        {
            icon: cash,
            optionHead: 'Cash',
            optionDes: 'Seize immediate market opportunities with swift and strategic trades in the spot market. Benefit from real-time price movements for optimal results.',
        },
        {
            icon: stockOption,
            optionHead: 'Stock Option',
            optionDes: 'Navigate options trading confidently with tailored strategies for income generation, risk management, and more. Make informed decisions in the dynamic options market.',
        },
        {
            icon: Delivery,
            optionHead: 'Delivery',
            optionDes: 'Build a long-term portfolio by investing in stocks aligned with your financial goals. Receive expert insights and analysis to guide your delivery-based investment decisions.',
        },
        {
            icon: Index,
            optionHead: 'Index',
            optionDes: 'Stay updated on key indices and leverage market movements. Make well-informed ideas to fine-tune your portfolio for success in the broader market.',
        },
        {
            icon: Futures,
            optionHead: 'Futures',
            optionDes: 'Explore derivative instruments and effectively hedge your positions. Engage with the future of trading guided by the successful trading experts’ view.',
        }

    ]
}
