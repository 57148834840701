import Skeleton from "react-loading-skeleton";

const OptionsSkeleton = () => {
  return (
    <div className=" filter-card">
      <Skeleton height={"25px"} width={"140px"} />
      <div className="radio-group">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <Skeleton height={"25px"} width={"25px"} borderRadius={"50%"} />
          </span>
          <span className="ms-2">
            <Skeleton height={"20px"} width={"40px"} />
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <Skeleton height={"25px"} width={"25px"} borderRadius={"50%"} />
          </span>
          <span className="ms-2">
            <Skeleton height={"20px"} width={"40px"} />
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <Skeleton height={"25px"} width={"25px"} borderRadius={"50%"} />
          </span>
          <span className="ms-2">
            <Skeleton height={"20px"} width={"40px"} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default OptionsSkeleton;
