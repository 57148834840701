import React from 'react';
import Skeleton from 'react-loading-skeleton';

const AdvisorsCardSkeleton = () => {
    return (
        <>
            <div className="ads-item card p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="ad-pro">
                            <h6> <Skeleton height={"68px"} width={'68px'} borderRadius={'50px'} /></h6>
                        </div>
                        <div className="ms-1 ps-2">
                            <div className="user-info">
                                <h5 className='mb-2'> <Skeleton height={"18px"} width={'180px'} /></h5>
                                <span> <Skeleton height={"16px"} width={'120px'} /></span>
                            </div>
                        </div>
                    </div>
                    <div className="cmt-count">
                        <div className="">
                            <Skeleton height={"50px"} width={'50px'} borderRadius={'50px'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdvisorsCardSkeleton;