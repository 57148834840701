import React from "react";
import MenuItems from "./component/MenuItems";

const Header = ({ active, setActive }: any) => {
  return (
    <>
      <MenuItems active={active} setActive={setActive} />
    </>
  );
};

export default Header;
