import Skeleton from "react-loading-skeleton";

const DateDrop = () => {
  return (
    <div className="filter-card">
      <div>
        <div className="user-info">
          <span>
            <Skeleton height={"20px"} width={"120px"} />
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          paddingTop: "20px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Skeleton height={"20px"} width={"120px"} />
        </div>
        <div>
          <Skeleton height={"20px"} width={"30px"} />
        </div>
      </div>
    </div>
  );
};

export default DateDrop;
