interface props {
    text?: string;
}

const SpinnerBtn = ({ text }: props) => {
    return (
        <div>
            <button
                className="primary-btn btn-common spinner-btn">{text && <span>{text}</span>}
                <div className={`spinner ${text && "ms-3"}`}></div>
            </button>
        </div>
    );
};

export default SpinnerBtn;