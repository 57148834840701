import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from '../../../../assets/lottie-files/success.json'
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
const Success = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Load animation
        const container = document.getElementById('form-success');
        if (container) {
            // Load animation
            const animation = lottie.loadAnimation({
                container,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData,
            });
            return () => {
                animation.destroy();
            };
        }
    }, []);

    const handleClick = () => {
        navigate("/");
    }

    return (
        <div>
            <div className='tab-content'>
                <div id="form-success" style={{ width: '250px', height: '250px', margin: '0 auto' }}>  </div>
                <div className='advisor-success'>
                    <div className='text-center'>
                        <h2>Submitted</h2>
                        <p>Your marketgenius sign up request has been submitted successfully.<br />We will review shortly and get back to you.</p>
                        <div className='bt-home'>
                            <PrimaryButton btnName={'Back to home'} btnType='button' onClick={() => handleClick()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;