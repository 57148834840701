import normalmcx from '../../assets/images/svgicons/normal-mcx.svg';
import bullions from '../../assets/images/svgicons/bullions.svg';
import energy from '../../assets/images/svgicons/energy.svg';
import Index from '../../assets/images/svgicons/index.svg';
import Delivery from '../../assets/images/svgicons/delivery.svg';

export const CommodityItems = {
    title: "Commodity",
    description: "The Fideal, we understand the dynamic nature of commodity markets and the importance of staying ahead in the game. Our Commodity Services are tailored to empower traders and investors with timely insights, trading experts’ analysis, and strategic view sharing across various segments.",
    explanation: [
        {
            icon: normalmcx,
            optionHead: 'Normal MCX',
            optionDes: `Unlock opportunities in the Normal MCX market with the views of MarketGenius' up-to-date insights and analysis. Stay ahead of trends and make informed decisions for your trading success.`,
        },
        {
            icon: bullions,
            optionHead: 'Bullions',
            optionDes: `Explore the precious world of Bullions – gold and silver. Navigate market fluctuations with the expert traders' advice to optimize your Bullion trading strategies.`,
        },
        {
            icon: energy,
            optionHead: 'Energy',
            optionDes: 'Stay informed in the fast-paced Energy market. From crude oil to natural gas, our dedicated Energy section provides key insights for effective decision-making in energy commodity trading.',
        },
        {
            icon: Index,
            optionHead: 'Metal',
            optionDes: `Dive into the strength of the Metal market. Our Metal segment offers concise views, helping you navigate the complexities of metal trading with confidence.`,
        },
        {
            icon: Delivery,
            optionHead: 'MCX Option',
            optionDes: 'MCX options represent a derivative financial instrument that allows market participants to trade the right, but not the obligation, to buy or sell commodities on the Multi Commodity Exchange of India (MCX) at a predetermined price within a specified time frame. The introduction of options on commodities provides traders and investors with additional flexibility in managing risk and capitalizing on market movements.',
        }

    ]
}
