import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import { useNavigate } from "react-router";

const AdvisorSignUp = () => {
  const navigate = useNavigate();

  return (
    <div className="advisor-card">
      <div className="advisors-signup">
        <div className="signup-cont">
          <h2>
            Within our trading community, we unite individuals with expert-level knowledge who actively engage in trading. Want to Share your knowledge and also want to become a MarketGenius?
          </h2>
        </div>
        <div>
          <SecondaryButton btnName={"MarketGenius Sign Up"} btnType={"button"} onClick={() => {
            navigate("/signup-request")
          }} />
        </div>
      </div>
    </div>
  );
};

export default AdvisorSignUp;
