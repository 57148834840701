import advisor from "../../../assets/images/advisor-ban.png";
import Banner from '../../common/banners/Banner';

const Disclaimer = () => {
    return (
        <div className="privacy-policy">
            <Banner
                title="Privacy Policy"
                description={``}
                imagePath={advisor}
            />

            <section className="large-cap">
                <div className="container">
                    <div className="disclaimer">
                        <p><span>DISCLAIMER: </span> We are NOT SEBI registered Advisors; we only allow sharing ideas and financial-related information from one to another trading community based on their practical trading experience. Kindly take the trades according to your risk and reward position and consult your advisor. It is advisable to take the advice of SEBI-registered advisors before entering into any of your trades. All posts/calls/tips/views/information shared are just for Educational purposes only. Non-Advisory, Discretional, No Claims, Rights reserved. We are not responsible for your losses. Consult your financial advisor before taking any position.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Disclaimer