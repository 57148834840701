import React, { useState } from 'react';
import GeneralDetails from './components/GeneralDetails';
import Banner from '../../../components/common/banners/Banner';
import service from "../../../assets/images/svgimage/banner-advisor.svg";
import Success from './components/Success';
import KycDetails from './components/KycDetails';
import tabSuccess from '../../../assets/images/svgicons/tab-check.svg'
import { Helmet } from 'react-helmet';
const AdvisorLogin = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <>
            <Helmet>
                <title>MarketGenius Sign Up | FiDeal</title>
            </Helmet>
            <div className='advisor-signup'>
                <Banner
                    title="MarketGenius Signup"
                    description="Please submit the general and kyc detail. Admin will review this form and activate your account."
                    imagePath={service}
                />
                <div className='large-cap'>

                    <div className='container'>
                        <div className='progress-line'></div>
                        <div className="progress-tab">
                            <div className='tabs-group'>
                                <div className={`tab-item ${selectedTab === 0 ? 'active-tab' : selectedTab >= 0 ? "success" : ""}`} >
                                    <div className='tabcount'>
                                        <span>01</span>
                                        <div className='tab-success'>
                                            <img src={tabSuccess} alt="check" />
                                        </div>
                                    </div>
                                    <div className='pro-label'>
                                        General Details
                                    </div>
                                </div>
                                <div className={`tab-item ${selectedTab === 1 ? 'active-tab' : selectedTab >= 1 ? "success" : ""}`} >
                                    <div className='tabcount'>
                                        <span>02</span>
                                        <div className='tab-success'>
                                            <img src={tabSuccess} alt="check" />
                                        </div>
                                    </div>
                                    <div className='pro-label'>
                                        KYC Details
                                    </div>
                                </div>
                                <div className={`tab-item ${selectedTab === 2 ? 'active-tab' : selectedTab >= 2 ? "success" : ""}`} >
                                    <div className='tabcount'>
                                        <span>03</span>
                                    </div>
                                    <div className='pro-label'>
                                        Progress
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='tab-body'>
                            {selectedTab === 0 && <GeneralDetails setSelectedTab={setSelectedTab} />}
                            {selectedTab === 1 && <KycDetails setSelectedTab={setSelectedTab} />}
                            {selectedTab === 2 && <Success />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdvisorLogin;