export const ourCompany = [
    {
        para: `Handpick MarketGenius aligning with their preferences.`
    },
    {
        para: `Assess and compare ideas from a variety of Successful MarketGenius.`
    },
    {
        para: `Access a diverse array of views seamlessly through a single, user-friendly portal.`
    }
]
