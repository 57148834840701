import { createSlice } from "@reduxjs/toolkit";
import { advisorState } from "../../interfaces/advisor";
import { changePasswordThunk } from "../../asyncThunks/Profile";

const initialState: advisorState = {
    error: null,
    data: null,
    loading: false,
}

export const changePassword = createSlice({
    name: "changePassword",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(changePasswordThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(changePasswordThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(changePasswordThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },

})


export default changePassword.reducer;
