import { useForm } from "react-hook-form";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import { useAppDispatch } from "../../store/store";
import { loginThunk } from "../../store/asyncThunks/auth";
import { useNavigate } from "react-router";
import Applogo from '../../assets/images/white-logo.svg';
import { NavLink } from "react-router-dom";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import loginImage from '../../assets/images/login-image.png';
import pswShow from '../../assets/images/svgicons/eye.svg'
import pswHide from '../../assets/images/svgicons/eye-off.svg'
import SpinnerBtn from "../../components/common/buttons/SpinnerBtn";
import { useSelector } from "react-redux";
import AdvisorLogin from "./components/AdvisorLogin";
import homeIcon from "../../assets/images/svgicons/home.svg";
import { setToken } from "../../helpers/api_helper";
import { jwtDecode } from "jwt-decode";
import { decodeToken } from "../../store/slices/auth/decodedToken";

const Login = () => {

  document.title = "Login | FiDeal";

  const dispatch = useAppDispatch();
  const [loginTab, setLoginTab] = useState(0);
  const [passwordShow, setPasswordShow] = useState(false);
  const [watchFields, setWatchFields] = useState<any>();

  const loading = useSelector((state: any) => state?.loginReducer?.loading)

  const decodeduserEmail: any = localStorage.getItem("signup-site");
  let userEmail: null | string = null;
  if (decodeduserEmail) {
    userEmail = atob(decodeduserEmail);
  };

  const handleTabSelect = (index: any) => {
    setLoginTab(index);
  };

  const navigate = useNavigate();
  const loginFormSchema = Yup.object().shape({
    email: Yup.string().required("Enter email address").test('email', 'Enter valid email address', val => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
      else return true
    }),
    password: Yup.string().required("Enter password"),

  });
  const { register, handleSubmit, reset, formState: { errors, }, watch } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginFormSchema),
  });

  const navigateAfterLogin = (accessToken: any) => {
    navigate("/");
    setToken();
    const decoded: any = jwtDecode(accessToken);
    dispatch(decodeToken(decoded));
  };

  const notVerifiy = () => {
    navigate('/verification');
  }

  const onSubmit = (values: any) => {
    const data: any = {};
    data["values"] = values;
    data["navigate"] = navigateAfterLogin;
    data["notVerifiy"] = notVerifiy;
    dispatch(loginThunk(data));
  };
  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setWatchFields(value)
    )
    return () => subscription.unsubscribe()
  }, [watch]);
  useEffect(() => {
    if (userEmail) {
      reset({
        email: userEmail,
        password: ""
      })
    }
  }, [userEmail])

  return (
    <div className="auth-screen">
      <div className="auth-overlayshape"></div>
      <div className="app-logo">
        <NavLink to={'/'}> <img src={Applogo} alt="Applogo" /></NavLink>
      </div>
      <NavLink to={'/'}>
        <div className="backto-home" >
          <img src={homeIcon} alt="homeIcon" />
        </div>
      </NavLink>
      <div className="row">
        <div className="col-lg-5">
          <div className="cont-bg">
            <div className="ripple-small"></div>
            <figure>
              <img src={loginImage} alt="lgin " />
            </figure>
            <div className="ripple-small right"></div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="auth-form">
            <div className="welcome-cont">
              <h2>Sign In</h2>
              <p>If you have an account with us, just sign in here below and access our portal with all the valuable views of our expertise and other relevant features.</p>
            </div>
            <div className='tabs-group'>
              <div className={`tab-item ${loginTab === 0 ? 'active-tab' : ""}`} onClick={() => { handleTabSelect(0) }}>
                <div className='pro-label'>
                  Trader
                </div>
              </div>
              <div className={`tab-item ${loginTab === 1 ? 'active-tab' : ""}`} onClick={() => { handleTabSelect(1) }}>
                <div className='pro-label'>
                  MarketGenius
                </div>
              </div>
            </div>
            <div className="tab-body mt-0 pt-0">
              {loginTab === 0 &&
                <>
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className='inpt-item mb-4'>
                      <input type='text' id='email' {...register("email")} className={`${errors.email?.message ? 'is-invalid ' : ''} form-control ${watchFields?.email?.length > 0 ? "valid-field" : ""}`} />
                      <label htmlFor='email' className='inpt-label'>Email address</label>
                      {errors.email?.message ? <span className={`invalid-feedback`} >{errors.email?.message}</span> : null}
                    </div>
                    <div className='inpt-item mb-4'>
                      <input type={passwordShow ? "text" : "password"} id='password' {...register("password")} className={`${errors.password?.message ? 'is-invalid ' : ''} form-control ${watchFields?.password?.length > 0 ? "valid-field" : ""}`} />
                      <label htmlFor='password' className='inpt-label'>Password</label>
                      {errors.password?.message ? <span className={`invalid-feedback`} >{errors.password?.message}</span> : null}
                      <span>  <button onClick={() => setPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                        {passwordShow ? <img src={pswHide} alt="password" /> : <img src={pswShow} alt="password" />}
                      </button></span>
                    </div>
                    <div className="text-end forgot">
                      <NavLink to='/forgot-password'><u>Forgot your password?</u></NavLink>
                    </div>
                    <div className="auth-btn">
                      {loading ?
                        <SpinnerBtn /> :
                        <PrimaryButton btnName="Sign In" btnType="submit" />}
                    </div>
                  </form>
                  <div className="back-to">
                    <p>Don’t have an account? <NavLink to={'/signup'}>Sign Up</NavLink></p>
                  </div>
                </>

              }
              {loginTab === 1 && <AdvisorLogin />}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Login;
