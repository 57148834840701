import React from 'react';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const AdvisorLogin = () => {

    const navigate = useNavigate();

    const advisorSignUp = () => {
        navigate('/signup-request')
    };

    const advisorLogin = () => {
        window.open(`${process.env.REACT_APP_ADMINPANEL}`)
        // navigate(`${process.env.REACT_APP_ADMINPANEL}`)
    };

    return (
        <div className='ad-login'>
            <div className='ad-login-cont'>
                <p>As an marketgenius, Please click the sign in button provided below to access marketgenius privileges.</p>
                <div className='login-btn'>
                    <PrimaryButton btnName="Sign In" btnType="submit" onClick={advisorLogin} />
                </div>
            </div>
            <div className='ad-login-cont'>
                <p> If you do not possess an marketgenius account, please proceed with the signup process.</p>
                <div className='login-btn'>
                    <PrimaryButton btnName="Sign Up" btnType="submit" onClick={advisorSignUp} />
                </div>
            </div>
        </div>
    );
};

export default AdvisorLogin;