import React from "react";

const ImageBlog = ({ imagePath, altName, layout, rippleIcon }: any) => {
  return (
    <div>
      <div
        className={`image-ripple-blog ${layout === 1 && "layout-one"} ${
          layout === 2 && "layout-two"
        } ${layout === 3 && "layout-three"}`}
      >
        <div className="ripple-small"></div>
        <figure>
          <img src={imagePath} alt={altName} />
        </figure>
        <div className="ripple-large">
          <img src={rippleIcon} alt={altName} />
        </div>
      </div>
    </div>
  );
};

export default ImageBlog;
