import React from "react";
import { Link } from "react-router-dom";

const CopyRights = () => {
  return (
    <div>
      <div className="">
        <p>
          FiDeal ©{new Date().getFullYear()} all rights reserved |&nbsp;
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
};

export default CopyRights;
