import { createSlice } from "@reduxjs/toolkit";
import { forgotpswState } from "../../interfaces/auth";
import { forgotPswThunk } from "../../asyncThunks/auth";

const initialState: forgotpswState = {
  error: null,
  data: null,
  loading: false,
};

export const forgotPassword = createSlice({
  name: "forgotpsw",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(forgotPswThunk.pending, (state, action) => {
      state.error = null;
      state.data = null;
      state.loading = true;
    });
    builder.addCase(forgotPswThunk.fulfilled, (state, action) => {
      state.error = null;
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(forgotPswThunk.rejected, (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {  } = login.actions;

export default forgotPassword.reducer;
