import IndependentLogo from '../../assets/images/svgicons/frame-1.svg';
import SebiLogo from '../../assets/images/svgicons/frame-2.svg';
import UniqLogo from '../../assets/images/svgicons/frame-3.svg';
import CostLogo from '../../assets/images/svgicons/frame-4.svg';


export const rolesData = [
    {
        image: IndependentLogo,
        title: 'Impartial Platform',
        description: `Impartial Platform: FiDeal maintains its independence by connecting MarketGenius with normal traders and investors abstaining from offering the MarketGenius views/Ideas/Information functioning solely as an unbiased platform.`
    },
    {
        image: SebiLogo,
        title: `Trade Community Network Connect`,
        description: `FiDeal acts as an inclusive platform, inviting an unlimited number of MarketGenius to share their insights and financial ideas through our portal.`
    },
    {
        image: UniqLogo,
        title: 'Unique Aggregator',
        description: `FiDeal stands as a pioneering solution in the country, being the first service provider to aggregate multiple MarketGenius views within a single platform.`
    },
    {
        image: CostLogo,
        title: 'Cost-Effective Solution',
        description: `Clients recognize FiDeal as a cost-effective platform, providing access to a multitude of trading ideas through a single login.`
    },
];