import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import { useAppDispatch } from "../../store/store";
import { loginThunk, signupThunk } from "../../store/asyncThunks/auth";
import { useNavigate } from "react-router";
import Applogo from '../../assets/images/white-logo.svg';
import { NavLink } from "react-router-dom";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import loginImage from '../../assets/images/login-image.png';
import pswShow from '../../assets/images/svgicons/eye.svg';
import pswHide from '../../assets/images/svgicons/eye-off.svg';
import { useSelector } from "react-redux";
import SpinnerBtn from "../../components/common/buttons/SpinnerBtn";
import homeIcon from "../../assets/images/svgicons/home.svg";
import PasswordStrengthBar from "react-password-strength-bar";

const Signup = () => {

    document.title = "Signup | FiDeal";

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [passwordShow, setPasswordShow] = useState(false);
    const [watchFields, setWatchFields] = useState<any>();
    const [registerPassword, setRegisterPassword] = useState('');


    const loading = useSelector((state: any) => state?.signupReducer?.loading)

    const signupForm = {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        password: ""
    };

    const signupFormSchema = Yup.object().shape({
        firstName: Yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters'),
        lastName: Yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters'),
        email: Yup.string().email('Enter valid email address').required("Enter email address").max(30, 'Email address should not exceed 30 characters'),
        mobileNumber: Yup.string().required("Enter mobile number").max(15, 'Mobile number should not exceed 15 characters'),
        password: Yup.string().required('Enter password').min(8, 'Password must be at least 8 characters long'),

    });

    const { register, handleSubmit, formState: { errors, }, getValues, setValue, watch, clearErrors, setError } = useForm({
        defaultValues: signupForm,
        resolver: yupResolver(signupFormSchema),
    });
    const navigateAfterSignup = () => {
        navigate("/verification");
    };

    const onSubmit = (values: any) => {
        const data: any = {};
        data["values"] = values;
        data["navigate"] = navigateAfterSignup;
        dispatch(signupThunk(data));
    };
    const handleWheel = (event: any) => {
        event.preventDefault(); // Prevent the default scrolling behavior
    };

    useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            setWatchFields(value)
        )
        return () => subscription.unsubscribe()
    }, [watch]);

    const handleChange = (event: any, field: any) => {
        setRegisterPassword(event.target.value);
        if (event.target?.value) {
            clearErrors(field)
        } else {
            setError(field, { type: "custom", message: 'Enter password' });
        }
        setValue(field, event.target.value)
    };

    return (
        <div className="auth-screen">
            <div className="auth-overlayshape"></div>
            <div className="app-logo">
                <NavLink to={'/'}> <img src={Applogo} alt="Applogo" /></NavLink>
            </div>
            <NavLink to={'/'}>
                <div className="backto-home" >
                    <img src={homeIcon} alt="homeIcon" />
                </div>
            </NavLink>
            <div className="row">
                <div className="col-lg-5">
                    <div className="cont-bg">
                        <div className="ripple-small"></div>
                        <figure>
                            <img src={loginImage} alt="login-img" />
                        </figure>
                        <div className="ripple-small right"></div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="auth-form">
                        <div className="welcome-cont">
                            <h2>Sign Up</h2>
                            <p>Easy and Simple Signup method.
                                Just fill the below given details and explore our valuable expert views and other important tools in the portal.
                            </p>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className='inpt-item mb-4'>
                                <input type='text' id='firstName' {...register("firstName")} className={`${errors.firstName?.message ? 'is-invalid ' : ''} form-control ${watchFields?.firstName?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='firstName' className='inpt-label'>First name</label>
                                {errors.firstName?.message ? <span className={`invalid-feedback`} >{errors.firstName?.message}</span> : null}
                            </div>
                            <div className='inpt-item mb-4'>
                                <input type='text' id='lastName' {...register("lastName")} className={`${errors.lastName?.message ? 'is-invalid ' : ''} form-control ${watchFields?.lastName?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='lastName' className='inpt-label'>Last name</label>
                                {errors.lastName?.message ? <span className={`invalid-feedback`} >{errors.lastName?.message}</span> : null}
                            </div>
                            <div className='inpt-item mb-4'>
                                <input type='text' id='email' {...register("email")} className={`${errors.email?.message ? 'is-invalid ' : ''} form-control ${watchFields?.email?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='email' className='inpt-label'>Email address</label>
                                {errors.email?.message ? <span className={`invalid-feedback`} >{errors.email?.message}</span> : null}
                            </div>
                            <div className='inpt-item mb-4'>
                                <input type='number' id='mobileNumber' {...register("mobileNumber")} className={`${errors.mobileNumber?.message ? 'is-invalid ' : ''} form-control ${watchFields?.mobileNumber?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='mobileNumber' className='inpt-label'>Mobile number</label>
                                {errors.mobileNumber?.message ? <span className={`invalid-feedback`} >{errors.mobileNumber?.message}</span> : null}
                            </div>
                            <div className='inpt-item mb-4'>
                                <input type={passwordShow ? "text" : "password"} id='password' {...register("password")} onChange={(e) => handleChange(e, "password")} className={`${errors.password?.message ? 'is-invalid' : ''} form-control ${watchFields?.password?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='password' className='inpt-label'>Password</label>
                                <span>  <button onClick={() => setPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                                    {passwordShow ? <img src={pswHide} alt="password" /> : <img src={pswShow} alt="password" />}
                                </button></span>
                                <div className="w-100">
                                    {registerPassword.length > 0 && (
                                        <PasswordStrengthBar password={registerPassword}
                                            scoreWords={['Very weak', 'weak', 'Medium', 'Good', 'Strong']}
                                            barColors={['#D7D7D7', '#EA1111', '#FFAD00', '#9bc158', '#00b500']}
                                        />
                                    )}
                                </div>
                                {errors.password?.message ? <span className={`invalid-feedback`} >{errors.password?.message}</span> : null}
                            </div>
                            <div className="auth-btn">
                                {loading ? <SpinnerBtn /> :
                                    <PrimaryButton btnName="Proceed" btnType="submit" />}
                            </div>
                        </form>
                        <div className="back-to">
                            <p>Already have an account? <NavLink to={'/login'}>Sign In</NavLink></p>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    );
};

export default Signup;
