import Banner from "../../components/common/banners/Banner";
import advisor from "../../assets/images/svgimage/banner-advisor.svg";
import TipsCard from "../../components/common/cards/TipsCard";
import refresh from "../../assets/images/svgicons/refresh-icon.svg";
import { useEffect, useState } from "react";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import RatingProgress from "./components/RatingProgress";
import SubmitReview from "./components/SubmitReview";
import ReviewCard from "./components/ReviewCard";
import { useDispatch, useSelector } from "react-redux";
import { getAdvisorByIdThunk, getTipByAdvisorFilterThunk } from "../../store/asyncThunks/Advisors";
import { getAdvisorTipsThunk, getTipsThunk } from "../../store/asyncThunks/Tips";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCategoryThunk } from "../../store/asyncThunks/Category";
import { reviewRatingThunk } from "../../store/asyncThunks/Rating";
import NoDataFound from "../../components/common/NoDataFound";
import TipsCardSkeletons from "../../components/common/skeleton/TipsCardSkeletons";
import ReviewCardSkeleton from "../../components/common/skeleton/ReviewCardSkeleton";
import RatingCardSkeleton from "../../components/common/skeleton/RatingCardSkeleton";
import { resetReviewRating } from "../../store/slices/advisors/reviewRating";
import AccordianMenuSkeleton from "../../components/common/skeleton/AccordianMenuSkeleton";
import AccordionMenu from "../../components/common/AccordionMenu";
import { resetData } from "../../store/slices/tips/getTips";
import RadioButton from "../../components/common/buttons/RadioButton";
import { dropOptions } from "../../constants/data/BuySellOptions";
import OptionsSkeleton from "../../components/common/skeleton/OptionsSkeleton";
import DateDrop from "../../components/common/skeleton/DateDrop";
import DropDown from "../../components/common/DropDown";
import { dateOptions } from "../../constants/data/DateOptions";
import arrowDown from "../../assets/images/svgicons/arrow-down.svg";
import ButtonSkeleton from "../../components/common/skeleton/ButtonSkeleton";
import ViewAdvisorBanner from "../../components/common/banners/ViewAdvisorBanner";
import LoadMoreSpinner from "../../components/common/LoadMoreSpinner";
import SpinnerBtn from "../../components/common/buttons/SpinnerBtn";
import { Helmet } from "react-helmet";
import Aos from "aos";
import ViewAdvisorSkeleton from "../../components/common/skeleton/ViewAdvisorSkeleton";

const ViewAdvisor = () => {
  const { id } = useParams();
  const params = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  const getAdvisor = useSelector((state: any) => state?.getadvisorByIDReducer?.data)
  const getAdvisorLoading = useSelector((state: any) => state?.getadvisorByIDReducer?.loading)
  const getTipsByAdvisorFilter = useSelector((state: any) => state?.getTipsByAdvisorFilterReducer?.data?.data)
  const AdvisorTipsLoading = useSelector((state: any) => state?.getTipsByAdvisorFilterReducer?.loading)
  const ratingCardLoading = useSelector((state: any) => state?.getadvisorByIDReducer?.loading)
  const reviewList = useSelector((state: any) => state?.reviewRatingReducer?.data?.ratings)
  const reviewTotalCount = useSelector((state: any) => state?.reviewRatingReducer?.data?.totalCount)
  const reviewListLoading = useSelector((state: any) => state?.reviewRatingReducer?.loading)
  const getTipsList = useSelector((state: any) => state?.getTipsReducer?.data);
  const getTipsLoading = useSelector((state: any) => state?.getTipsReducer?.loading);
  const getCategoryList = useSelector((state: any) => state?.getCategoryReducer?.data?.category);
  const getCategoryListLoader = useSelector((state: any) => state?.getCategoryReducer?.loading);

  const pageSize = 5;
  const dataPerPage = process.env.REACT_APP_DATA_PERPAGE_TIPS || 14;

  const [tipsCurrentPage, setTipsCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewData, setReviewData] = useState<any>([]);
  const [starCount, setStarCount] = useState(0);
  const [stars, setstars] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
  });
  const [listData, setListData] = useState<any>([]);
  const [canAddData, setCanAddData] = useState(true);
  const [filter, setFilter] = useState({
    // dateFilter: "thirtydays",
    dateFilter: 0,
    guide: [id], // selected advisors id in []
    subService: null, // selected sub-categories id in []
    tip: null, // buy or sell
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [date, setDate] = useState({ label: "Today", value: 0 });


  const handleChange = (event: any) => {
    const newFilter = {
      ...filter,
      tip: event.target.value === "ALL" ? null : event.target.value,
    };
    setFilter(newFilter);
  };

  const handleDateFilter = (value: any) => {
    const newFilter = { ...filter, dateFilter: value?.value };
    setFilter(newFilter);
    setDate(value);
  };

  const isChecked = (value: any) => {
    if (!filter?.tip && value == "ALL") {
      return true;
    } else {
      return filter?.tip === value;
    }
  };

  const handleAddMergeData = () => {
    if (getTipsList?.data?.length > 0) {
      const newData = [...listData, ...getTipsList?.data];
      setListData(newData);
      if (newData?.length === getTipsList?.totalCount) {
        setCanAddData(false);
      } else {
        setCanAddData(true);
      }
    }
  };

  useEffect(() => {
    handleAddMergeData();
  }, [getTipsList?.data]);

  const getTips = (payload: any) => {
    const data: any = {};
    data["payload"] = payload;
    data["setOpenFilter"] = setOpenFilter;
    data["setFilter"] = setFilter;
    data["currentPage"] = tipsCurrentPage;
    data["dataPerPage"] = dataPerPage;
    dispatch(getTipsThunk(data));
  };

  useEffect(() => {
    getTips(filter);
  }, [tipsCurrentPage, filter]);

  //get advisor by id & get tips by advisor
  useEffect(() => {
    dispatch(resetReviewRating())
    dispatch(getAdvisorByIdThunk(id));
  }, [id]);

  useEffect(() => {
    if (getAdvisor?.ratingCounts) {
      const updatedStarValue = {
        one: getAdvisor?.ratingCounts[`1stars`],
        two: getAdvisor?.ratingCounts[`2stars`],
        three: getAdvisor?.ratingCounts[`3stars`],
        four: getAdvisor?.ratingCounts[`4stars`],
        five: getAdvisor?.ratingCounts[`5stars`],
      }
      setstars(updatedStarValue)
    }
  }, [getAdvisor]);

  useEffect(() => {
    dispatch(reviewRatingThunk({ AdvisorId: id, currentPage, pageSize }));
  }, [id, currentPage]);

  const loadMoreReviews = async () => {
    if (reviewData?.length != reviewTotalCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (reviewList?.length > 0) {
      const newReviewData = [...reviewData, ...reviewList]
      setReviewData(newReviewData);
    }
  }, [reviewList]);

  // TO-DO
  //to check the scroll is reached last or not
  // const handleScroll = async () => {
  //   const innerBodyHeight: any = document.querySelector(".tips-section");
  //   if (listData?.length != getTipsList?.totalCount && canAddData) {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop <
  //       innerBodyHeight.offsetHeight ||
  //       getTipsLoading
  //     ) {
  //       return;
  //     }

  //     const newPage = tipsCurrentPage + 1;
  //     setTipsCurrentPage(newPage);
  //   }
  // };

  //starting scroll eventlistener
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [getTipsLoading, canAddData]);

  function resetList() {
    dispatch(resetData());
    setListData([]);
    setTipsCurrentPage(1);
  }

  useEffect(() => {
    if (showFilter == false) {
      const handleShowTips = async () => {
        await resetList();
      };
      handleShowTips();
    }
  }, [filter]);

  useEffect(() => {
    let width = window.innerWidth;
    if (width <= 1220) {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
    dispatch(getCategoryThunk());
    return () => dispatch(resetReviewRating())
  }, []);

  const handleApply = async () => {
    if (showFilter) {
      await resetList();
      getTips(filter);
    }
  };

  const handleLoadMore = () => {
    const newPage = tipsCurrentPage + 1;
    setTipsCurrentPage(newPage);
  }
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Helmet>
        <title>Advisor Details | FiDeal</title>
      </Helmet>
      <div className="advisor-view tips">
        <div className="common-banner">
          <div className="banner-bg">

            <div className="container">
              {getAdvisorLoading === false ?
                <ViewAdvisorBanner
                  title={getAdvisor && getAdvisor?.advisor?.firstName + ' ' + getAdvisor?.advisor?.lastName}
                  description={getAdvisor?.advisor?.description}
                  imagePath={getAdvisor && getAdvisor?.advisor?.profilePath}
                  sebiNumber={getAdvisor && `SEBI: ${getAdvisor?.advisor?.sebiNumber || ''}`}
                  reviewRating={getAdvisor?.advisor?.overallRating || 0}
                />
                :
                <ViewAdvisorSkeleton />
              }
            </div>

          </div>
        </div>
        <section className={`large-cap tips-section ${openFilter ? "open-filter" : ""}`}>
          <div className="filter-icon">
            <div
              className="filter-toggle"
              onClick={() => setOpenFilter(!openFilter)}
            >
              <button className="filter-open">
                <img src={arrowDown} alt="" />
              </button>
              <button className="filter-close">
                <img src={arrowDown} alt="" />
              </button>
            </div>
          </div>
          <div className="container">
            {/* <div className="btn-grp">
              <div>
                <h2 className="">Recent Tips</h2>
              </div>
            </div> */}

            <div className="tips-details">
              <div className="row">
                <div className="col-lg-4 tips-filter" data-aos="fade-in">
                  {getCategoryListLoader == true ? (
                    <div className="accordion-menu">
                      {[...Array(3)].map((index) => {
                        return <AccordianMenuSkeleton key={`advisor${index}`} />;
                      })}
                    </div>
                  ) : (
                    <AccordionMenu
                      categories={getCategoryList}
                      values={filter}
                      setValues={setFilter}
                    />
                  )}

                  <div className="filter-card">
                    {getTipsLoading == true && getCategoryListLoader ? (
                      <DateDrop />
                    ) : (
                      <div className="date-filter">
                        <h5 className="title sm">Date</h5>
                        <DropDown
                          options={dateOptions}
                          classNamePrefix="custom_select"
                          // search={true}
                          value={date}
                          name="role"
                          placeholder={`Select date`}
                          onChange={handleDateFilter}
                          mainClass="md"
                        />
                      </div>
                    )}

                    {getTipsLoading == true && getCategoryListLoader ? (
                      <OptionsSkeleton />
                    ) : (
                      <div className="options">
                        <h5 className="title sm">Options</h5>
                        <div className="radio-group">
                          {dropOptions?.map((item: any, index: number) => {
                            const { value, label } = item;
                            return (
                              <RadioButton
                                key={index}
                                id={value}
                                name="options"
                                value={value}
                                text={label}
                                onChange={handleChange}
                                checked={isChecked(value)}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  {showFilter && (
                    <div className="btn-action">
                      {getTipsLoading == true ? (
                        <ButtonSkeleton />
                      ) : (
                        <PrimaryButton
                          btnName={"Apply"}
                          onClick={() => handleApply()}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="col-lg-8" data-aos="fade-up">
                  <div className="filter">
                    {/* TO-DO */}
                    {/* <div className="refresh">
                      <img src={refresh} alt="refresh" />
                      <p className="ms-2">Last Refreshed 3m ago</p>
                    </div> */}
                    {/* <div className="action">
                      <div className="view-btn">
                        <PrimaryButton btnName="View All"
                          onClick={() => {
                            navigate("/tips");
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                  {getTipsLoading && tipsCurrentPage === 1 &&
                    <div className="tips-group pt-0 mt-0">
                      {[...Array(6)]?.map((item: any, index: number) => {
                        return <TipsCardSkeletons key={`tip-card-skeleton-${index}`} />
                      })}
                    </div>
                  }
                  {listData && <div className="tips-group pt-0 mt-0">
                    {listData?.map((tips: any) => (
                      <TipsCard tips={tips} key={tips._id} listData={listData} setListData={setListData} />
                    ))}
                  </div>
                  }
                  {(listData?.length === 0 || !listData) && getTipsLoading === false && <NoDataFound />}
                  {/* {getTipsLoading === true && <div className="mt-5"><LoadMoreSpinner /></div>} */}

                  {listData?.length != getTipsList?.totalCount &&
                    <div className="load-more-button mt-5">
                      {
                        getTipsLoading ? <SpinnerBtn /> :
                          <PrimaryButton btnName="Load More" btnType='button' onClick={() => handleLoadMore()} />
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="large-cap rating-review-section">
          <div className="container">
            <div className="btn-grp" data-aos="fade-in">
              <div>
                <h2 className="">Ratings & Reviews</h2>
              </div>
            </div>

            <div className="card" data-aos="fade-up">
              <div className="row w-100">
                {ratingCardLoading ? (
                  <RatingCardSkeleton />
                ) : (
                  <RatingProgress stars={stars}
                    ratingCount={getAdvisor?.advisor?.overallRating}
                    reviewCount={getAdvisor?.advisor?.reviewCount}
                  />
                )}
                <SubmitReview
                  setStarCount={setStarCount}
                  starCount={starCount}
                  advisorId={getAdvisor?.advisor?._id}
                />
              </div>
            </div>

            {reviewListLoading && currentPage === 1 &&
              <div className="review-group">
                <ReviewCardSkeleton />
              </div>
            }

            {(
              reviewData && reviewData?.length > 0 &&
              <div className="review-group" data-aos="fade-up">
                <ReviewCard reviewRating={reviewData} loadMore={loadMoreReviews} reviewTotalCount={reviewTotalCount} loader={reviewListLoading} />
              </div>
            )}
          </div>
        </section >
      </div >
    </>
  );
};

export default ViewAdvisor;
