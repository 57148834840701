const RadioButton = ({ id, text, name, onChange, checked, value }: any) => {
  return (
    <label htmlFor={id} className="radio-label">
      <input
        type="radio"
        className="radiobutton-input"
        name={name}
        id={id}
        onChange={onChange}
        checked={checked}
        value={value}
      />
      <span className="custom-radiobutton" />
      <span className="text">{text}</span>
    </label>
  );
};

export default RadioButton;
