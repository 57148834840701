import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import homeIcon from '../../../assets/images/svgicons/home-icon.svg';
import homeOutlineIcon from '../../../assets/images/home-outline.png';
import HeaderProfile from "./HeaderProfile";
import { jwtDecode } from "jwt-decode";
import Notification from "./Notification";
import ConfirmationModal from "../../common/modal/ConfirmationModal";
import { useAppSelector } from "../../../store/store";

const MenuItems = ({ active, setActive }: any) => {

  const navigate = useNavigate();

  const accessToken = localStorage.getItem('auth-user');

  const decodedData = useAppSelector(state => state.decodeTokenReducer.data)

  const [isActive, setIsActive] = useState(false);

  const [showmodel, setshowmodel] = useState(false);

  const handleClick = (event: any) => {
    setIsActive((current) => !current);
  };

  const handleLinckClick = (props: any) => {
    const { link, isProtected } = props;
    if (isProtected) {
      if (accessToken) {
        navigate(`${link}`);
        setActive(link)
      } else {
        setshowmodel(true);
      }
    } else {
      setActive(link)
      navigate(`${link}`);
    }
    setIsActive(false)
  }

  const toogleModal = () => {
    setshowmodel(!showmodel)
  }

  const navigateAfterLogin = () => {
    localStorage.clear();
    navigate('/login');
  }

  const isSticky = (e: any) => {
    const header = document.querySelector(".header-section");
    if (header) {
      const scrollTop = window.scrollY;
      scrollTop >= 50
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  return (
    <div className={`header-section ${isActive ? "nav-open" : ""}`}>
      <header id="header">
        <div className="sm-logo">
          <Logo />
        </div>
        <div>
          <div className="mobile-notify">
            <Notification />
          </div>
          <button className="nav-toggle" onClick={handleClick}>
            <div className="hamburger">
              <span></span>
            </div>
          </button>
        </div>
        <nav className="nav" id="navbar">
          <div className="logo">
            <Logo />
          </div>
          <ul className="nav_list" id="navlinkitems">
            <li className="nav_item">
              <NavLink to={`/`} className={({ isActive }) => `nav_link ${isActive ? "active-item" : ''}`} onClick={() => handleLinckClick({ link: '/', isProtected: false })}>
                <div className="home-icons">
                  <img src={homeIcon} alt="home-icon" className="home-fill-icon" />
                  <img src={homeOutlineIcon} alt="home-icon" className="home-outline-icon" />
                </div>
                <span className="home-txt">Home</span>
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to={`/services`} className={({ isActive }) => `nav_link ${isActive ? "active-item" : ''}`} onClick={() => handleLinckClick({ link: '/services', isProtected: false })}>
                KnowledgeHub
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to={`/geniustalk`} className={({ isActive }) => `nav_link ${isActive ? "active-item" : ''}`} onClick={() => handleLinckClick({ link: '/geniustalk', isProtected: false })}>
                GeniusTalk
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to={`/marketgenius`} className={({ isActive }) => `nav_link ${isActive ? "active-item" : ''}`} onClick={() => handleLinckClick({ link: '/marketgenius', isProtected: false })}>
                MarketGenius
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to={`/about-us`} className={({ isActive }) => `nav_link ${isActive ? "active-item" : ''}`} onClick={() => handleLinckClick({ link: '/about-us', isProtected: false })}>
                About Us
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to={`/contact-us`} className={({ isActive }) => `nav_link ${isActive ? "active-item" : ''}`} onClick={() => handleLinckClick({ link: '/contact-us', isProtected: false })}>
                Contact Us
              </NavLink>
            </li>
            <li className="nav_item ">
              <Notification />
            </li>
            {decodedData ? (
              <li><HeaderProfile userData={decodedData} /></li>
            ) : (
              <li className="nav_item">
                <NavLink to="/login" className={`nav_link`}>
                  Sign In / Sign Up
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </header>
      <ConfirmationModal
        showModal={showmodel}
        toggle={toogleModal}
        modalTitle={'Login Required!!!'}
        description={'Kindly log in to access the exclusive privileges.'}
        primaryBtn={'Sign In'}
        primaryAction={navigateAfterLogin}
      />
    </div>
  );
};

export default MenuItems;
