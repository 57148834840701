import React from "react";
import { NavLink } from "react-router-dom";

const FooterMenu = () => {
  return (
    <div className="footer-menu">
      <div>
        <h5 className="foot-title">KnowledgeHub</h5>
        <ul>
          <li>
            <NavLink to="/services/currency">Currency</NavLink>
          </li>
          <li>
            <NavLink to="/services/commodity">Commodity</NavLink>
          </li>
          <li>
            <NavLink to="/services/equity">Equity</NavLink>
          </li>
        </ul>
      </div>
      <div>
        <h5 className="foot-title">Useful Links</h5>
        <ul>
          <li>
            <NavLink to="/geniustalk">GeniusTalk</NavLink>
          </li>
          <li>
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterMenu;
