import { Modal } from 'reactstrap'
import like from '../../../assets/images/svgicons/like.svg';
import like_outline from '../../../assets/images/svgicons/like_outline.svg';
import dislike from '../../../assets/images/svgicons/dislike.svg';
import dislike_fill from '../../../assets/images/svgicons/dislike_fill.svg';
import closeIcon from '../../../assets/images/svgicons/close.svg';
import moment from 'moment';

interface props {
    showModal: boolean,
    toggle: any,
    modalTitle: string,
    tipData: any,
    size?: string
}

const ViewTipsModel = ({ showModal, toggle, modalTitle, tipData, size = "md" }: props) => {
    return (
        <Modal size={size} isOpen={showModal} centered={true}
            className='view-tips-modal'
            toggle={toggle}
        >
            <div className="modal-header px-0 border-0">
                <div className='head-item'>
                    <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                </div>
                <span onClick={toggle} aria-hidden="true" className='close-icon'>
                    <img src={closeIcon} alt="close" />
                </span>
            </div>
            <div className="modal-body custom-scroll">
                <div className='tip-content pt-1'>
                    <div className='blog-header pb-2'>
                        <div className='w-100'>
                            <ul>
                                <li>
                                    <p className='m-0 text-capitalize main-cat'>{tipData?.category?.label}&nbsp;&nbsp;<span className='text-capitalize sub-cat'>{tipData?.subcategory?.label}</span></p>
                                </li>
                                <li>
                                    <div className='badge p-0'>
                                        <p className={`stock-tiptype m-0 ${tipData?.stockTip?.toLowerCase().replaceAll("_", "-")}`}>
                                            {tipData?.stockTip?.replace(/_/g, ' ')}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li className='d-flex'>
                                    <img src={tipData?.reacted == "YES" ? like : like_outline} alt='profile' className='me-2' />
                                    {tipData?.likesCount}</li>
                                <li className='d-flex'>
                                    <img src={tipData?.reacted == "NO" ? dislike_fill : dislike} alt='profile' className='me-2' />
                                    {tipData?.disLikesCount}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='descriptions'>
                        <div>
                            {
                                tipData?.description?.map((item: any) => {
                                    return <>
                                        <h5 className={`py-2 mb-0`} key={item?._id} >{`${item?.description}`}</h5>
                                        <p className='date-time border-bottom'>
                                            {item?.updatedAt ? moment(item?.updatedAt).format('ll') : ""}{" "}{item?.updatedAt ? moment(item?.updatedAt).format('LT') : ""}
                                        </p>
                                    </>
                                })
                            }
                        </div>
                    </div>
                    <ul className='tips-auth-info'>
                        <>
                            <li className='text-capitalize d-flex align-items-center'>
                                <div className='rounded-circle ad-profile'>
                                    <div className="advisor-pic rounded-circle">
                                        {
                                            tipData?.advisor?.profilePath ?
                                                <img className='rounded-circle' src={tipData?.advisor?.profilePath} alt="profile-picture" /> :
                                                <span>{tipData?.advisor?.firstName?.charAt(0)}</span>
                                        }
                                    </div>

                                </div>
                                <p className='ad-name'>{tipData?.advisor?.firstName + " " + tipData?.advisor?.lastName}</p>
                            </li>
                        </>
                    </ul>
                </div>
            </div>
        </Modal>
    )
}

export default ViewTipsModel