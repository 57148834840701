import { useNavigate } from "react-router";
import commentsIcon from "../../../assets/images/svgicons/comments-icon.svg";
import ViewRating from "../ViewRating";
import { advisorTypes } from "../../../types/advisorTypes";


const AdvisorsCard = ({ advisors }: advisorTypes) => {



  const navigate = useNavigate();

  return (
    <>
      <div className="ads-item card p-3">
        <div
          className="d-flex align-items-center justify-content-between"
          onClick={() => {
            navigate(`/marketgenius/view/${advisors?._id}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex align-items-center">
            <div className="ad-pro">
              {advisors?.profilePath ? (
                <img src={advisors?.profilePath} alt={"profile-image"} />
              ) : (
                <>
                  {advisors?.profileImg ? <img src={advisors?.profileImg} alt={"profile-image"} /> :
                    <h6>{advisors.firstName.charAt(0).toUpperCase()}</h6>
                  }
                </>
              )}
            </div>
            <div className="ms-1 ps-2">
              <div className="user-info">
                <h5>{advisors?.firstName && advisors?.lastName ? advisors?.firstName + " " + advisors?.lastName : ""}</h5>
                <ViewRating rating={advisors?.overallRating || 0} />
              </div>
            </div>
          </div>
          <div className="cmt-count">
            <div className="">
              <img src={commentsIcon} alt="commentsIcon" />
              <span className="badge">{advisors?.reviewCount || 0}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvisorsCard;
