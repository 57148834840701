import React, { DragEvent, ChangeEvent, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import uploadIcon from '../../../../assets/images/svgicons/upload-icon.svg'
import DropDown from '../../../../components/common/DropDown';
import OutlineButton from '../../../../components/common/buttons/OutlineButton';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { addressProofOptions, idProofOptions, sebiProofOptions } from '../../../../constants/data/ProofOptions';
import { addGeneralDetails, resetSignupRequest } from '../../../../store/slices/signupRequest/signupRequest';
import { addSignupRequestThunk } from '../../../../store/asyncThunks/SignupRequest';
import SpinnerBtn from '../../../../components/common/buttons/SpinnerBtn';

const KycDetails = ({ setSelectedTab }: any) => {

    const dispatch = useAppDispatch();

    const signupRequestData = useAppSelector(state => state.signupRequest.data);
    const addSignupRequestLoader = useAppSelector(state => state.addSignupRequestReducer.loading);
    const [idProofDropValue, setIdProofDropValue] = useState({ label: "Select id proof", value: "" });
    const [addressProofDropValue, setAddressProofDropValue] = useState({ label: "Select address proof", value: "" });
    const [sebiProofDropValue, setSebiProofDropValue] = useState({ label: "Select register proof", value: "" });
    const [watchFields, setWatchFields] = useState<any>();

    const max_file_size = 250;

    const defaultValues = {
        selectedIdProof: "",
        selectedAddressProof: "",
        selectedRegisterProof: "",
        idNumber: "",
        address: "",
        sebiNumber: "",
        idProof: "",
        addressProof: "",
        sebiCertificate: "",
    };

    const ContactFormSchema = yup.object().shape({
        selectedIdProof: yup.string().required("Select ID proof"),
        selectedAddressProof: yup.string().required("Select address proof"),
        selectedRegisterProof: yup.string().required("Select Register proof"),
        idNumber: yup.string().required("Enter ID proof number").trim(),
        address: yup.string().required("Enter address").trim(),
        sebiNumber: yup.string().trim(),
        idProof: yup.mixed().required("Upload ID proof").test('is-valid-size', 'File size should be less than 500kb', (value: any) => {
            if (value && (value.size / 1024) > max_file_size) {
                return false;
            } else {
                return true;
            }
        }),
        addressProof: yup.mixed().required("Upload address proof").test('is-valid-size', 'File size should be less than 500kb', (value: any) => {
            if (value && (value.size / 1024) > max_file_size) {
                return false;
            } else {
                return true;
            }
        }),
        sebiCertificate: yup.mixed().test('is-valid-size', 'File size should be less than 500kb', (value: any) => {
            if (value && (value.size / 1024) > max_file_size) {
                return false;
            } else {
                return true;
            }
        }),
    });

    const { register, handleSubmit, reset, watch, setValue, setError, clearErrors, getValues, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(ContactFormSchema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (values: any) => {
        let isFormError = true, isFileError = true;
        await trigger().then((isErrorCleared) => {
            if (isErrorCleared) {
                isFormError = false;
            } else {
                isFormError = true;
            }
        });

        if (!values?.idProof) {
            setError('idProof', { type: 'custom', message: 'Upload ID proof' });
        }
        if (!values?.addressProof) {
            setError('addressProof', { type: 'custom', message: 'Upload address proof' });
        }
        if (values?.selectedRegisterProof === "SEBI_REGISTER" && !values.sebiNumber) {
            isFormError = true;
            setError('sebiNumber', { type: 'custom', message: 'SEBI number is Required' });
        }
        if (values?.selectedRegisterProof === "SEBI_REGISTER" && !values.sebiCertificate) {
            isFormError = true;
            setError('sebiCertificate', { type: 'custom', message: 'SEBI cetrificate is Required' });
        }
        if (values?.selectedRegisterProof !== "SEBI_REGISTER") {
            setValue('sebiCertificate', '')
            setValue('sebiNumber', '')

        }

        if (values?.idProof && values?.addressProof) {
            isFileError = false;
        }
        if (values?.selectedRegisterProof === "SEBI_REGISTER" && values?.sebiCertificate) {
            isFileError = false;
        }

        const isError = Object?.keys(errors);

        if (!isFormError && !isFileError && isError?.length === 0) {
            dispatch(addGeneralDetails(values));
            const payload: any = {};
            //from general details
            payload['fName'] = values?.firstName;
            payload['lName'] = values?.lastName;
            payload['emailAddress'] = values?.email;
            payload['mobNum'] = values?.phoneNumber;
            payload['desc'] = values?.decription;
            payload['profile'] = values?.profile;
            //from kyc details
            payload['selectedIdProof'] = values?.selectedIdProof;
            payload['selectedAddressProof'] = values?.selectedAddressProof;
            payload['idNum'] = values?.idNumber;
            payload['add'] = values?.address;

            payload['idProofImg'] = values?.idProof;
            payload['addressProofImg'] = values?.addressProof;
            payload['selectedRegisterProof'] = values?.selectedRegisterProof;
            if (values?.selectedRegisterProof === "SEBI_REGISTER") {
                payload['sebiCertificateImg'] = values?.sebiCertificate;
                payload['sebiNum'] = values?.sebiNumber;
                payload['sebiRegisterd'] = "YES";
            } else {
                payload['sebiRegisterd'] = "NO";
            }
            dispatch(addSignupRequestThunk({ payload, successFunction: handleUploadSucess }));
        }

    }

    const handleDrop = (event: DragEvent<HTMLDivElement>, field: any) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            if ((files[0]?.size / 1024) > max_file_size) {
                setError(field, { type: 'custom', message: "File size should be less than 500kb" })
            } else {
                clearErrors(field)
            }
            setValue(field, files[0]);
            // clearErrors(field)
        }
    };

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>, field: any) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            if ((files[0]?.size / 1024) > max_file_size) {
                setError(field, { type: 'custom', message: "File size should be less than 500kb" })
            } else {
                clearErrors(field)
            }
            setValue(field, files[0]);
            // clearErrors(field)
        }
    };

    const preventDefault = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const setIdProofValue = (value: string | null) => {
        const result = idProofOptions?.filter((item) => item?.value === value)
        if (result?.length > 0) {
            setIdProofDropValue(result[0])
        } else {
            setIdProofDropValue({ label: "Select id proof", value: "" })
        }
    }
    const setAddressProofValue = (value: string | null) => {
        const result = idProofOptions?.filter((item) => item?.value === value)
        if (result?.length > 0) {
            setAddressProofDropValue(result[0])
        } else {
            setAddressProofDropValue({ label: "Select address proof", value: "" })
        }
    }

    const setRegisterProofValue = (data: string) => {
        const result = sebiProofOptions?.filter((item) => item?.value === data)
        if (result?.length > 0) {
            setSebiProofDropValue(result[0])
        } else {
            setSebiProofDropValue({ label: "Select register proof", value: "" })
        }
    }

    useEffect(() => {
        if (signupRequestData?.selectedIdProof) {
            setIdProofValue(signupRequestData?.selectedIdProof)
        }
        if (signupRequestData?.selectedAddressProof) {
            setAddressProofValue(signupRequestData?.selectedAddressProof)
        }
        if (signupRequestData?.selectedRegisterProof) {
            setRegisterProofValue(signupRequestData?.selectedRegisterProof)
        }
        reset(signupRequestData);
        setWatchFields(signupRequestData)
    }, [signupRequestData])

    useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            setWatchFields(value)
        )
        return () => subscription.unsubscribe()
    }, [watch])

    const handleClear = () => {
        setIdProofValue(null)
        setAddressProofValue(null)
        reset({ ...signupRequestData, ...defaultValues });
    }

    const handleIdProofChange = ({ value, setDropValue, field }: any) => {
        setDropValue(value);
        setValue(field, value?.value);
        clearErrors(field);
    }

    const handleInputChange = (value: any, field: any, message: string) => {
        if (value?.length > 0) {
            clearErrors(field)
        } else {
            setError(field, { type: 'custom', message: "Enter " + message });
        }
        setValue(field, value)
    }

    const handleUploadSucess = () => {
        handleChangeTab(2);
        dispatch(resetSignupRequest())
    }

    const handleChangeTab = (tab: number) => {
        setSelectedTab(tab);
    }

    const handleBack = () => {
        dispatch(addGeneralDetails(getValues()));
        handleChangeTab(0)
    }

    return (
        <div className='tab-content kyc-details-tab'>
            <h5>KYC Details</h5>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className='row'>

                    <div className='col-lg-12'>
                        <div className='form-layout'>
                            <div className='w-100 mob-res'>
                                <div className='form-group'>
                                    <div className='inpt-item mb-4 id-num'>
                                        <div className='mb-4'>
                                            <div className={`${errors.selectedIdProof?.message && 'is-invalid'}`}>
                                                <DropDown
                                                    options={idProofOptions}
                                                    classNamePrefix="custom_select"
                                                    value={idProofDropValue}
                                                    name="role"
                                                    onChange={(e: any) => handleIdProofChange({ value: e, setDropValue: setIdProofDropValue, field: 'selectedIdProof' })}
                                                />
                                            </div>
                                            {errors.selectedIdProof?.message ? <span className={`invalid-feedback`} >{errors.selectedIdProof?.message}</span> : null}
                                        </div>
                                        <input type='text' id='idNumber' value={watchFields?.idNumber} name="idNumber" className={`form-control ${errors.idNumber?.message && 'is-invalid'} ${watchFields?.idNumber?.length > 0 && 'valid-field'}`} onChange={(e) => handleInputChange(e.target.value, "idNumber", "ID proof number")} />
                                        <label htmlFor='idNumber' className='inpt-label'>ID Number</label>
                                        {errors.idNumber?.message ? <span className={`invalid-feedback`} >{errors.idNumber?.message}</span> : null}
                                        <div className='mt-4'>
                                            <div className={`${errors.idProof?.message && 'is-invalid'}`}>
                                                <div
                                                    onDrop={(e) => handleDrop(e, 'idProof')}
                                                    onDragOver={preventDefault}
                                                    onDragEnter={preventDefault}
                                                    style={{
                                                        border: '2px dashed #ccc',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    className='upload-container'
                                                >
                                                    <input
                                                        type="file"
                                                        onChange={(e) => handleFileInputChange(e, 'idProof')}

                                                    />
                                                    <div className='upload-content'>
                                                        <div className='upload-icon'>
                                                            <img src={uploadIcon} alt='upload' />
                                                        </div>
                                                        <h5 className='p-0'>Upload Id Proof</h5>
                                                        <p>File Should be png, jpeg, jpg.</p>
                                                    </div>

                                                </div>
                                                {watchFields?.idProof && (
                                                    <p>
                                                        Selected File: {watchFields?.idProof.name} ({(watchFields?.idProof.size / 1024).toFixed(2)} KB)
                                                    </p>
                                                )}
                                            </div>
                                            {errors.idProof?.message ? <span className={`invalid-feedback`} >{errors.idProof?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='inpt-item mb-4 id-num'>
                                        <div className='mb-4'>
                                            <div className={`${errors.selectedAddressProof?.message && 'is-invalid'}`}>
                                                <DropDown
                                                    options={addressProofOptions}
                                                    classNamePrefix="custom_select"
                                                    value={addressProofDropValue}
                                                    name="role"
                                                    onChange={(e: any) => handleIdProofChange({ value: e, setDropValue: setAddressProofDropValue, field: 'selectedAddressProof' })}
                                                />
                                            </div>
                                            {errors.selectedAddressProof?.message ? <span className={`invalid-feedback`} >{errors.selectedAddressProof?.message}</span> : null}
                                        </div>
                                        <input type='text' id='address' className={`form-control ${errors.address?.message && 'is-invalid'} ${watchFields?.address?.length > 0 && 'valid-field'}`} value={watchFields?.address} name="address" onChange={(e) => handleInputChange(e.target.value, "address", 'address')} />
                                        <label htmlFor='address' className='inpt-label'>Address</label>
                                        {errors.address?.message ? <span className={`invalid-feedback`} >{errors.address?.message}</span> : null}
                                        <div className='mt-4'>
                                            <div className={`${errors.addressProof?.message && 'is-invalid'}`}>
                                                <div
                                                    onDrop={(e) => handleDrop(e, 'addressProof')}
                                                    onDragOver={preventDefault}
                                                    onDragEnter={preventDefault}
                                                    style={{
                                                        border: '2px dashed #ccc',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    className='upload-container'
                                                >
                                                    <input
                                                        type="file"
                                                        onChange={(e) => handleFileInputChange(e, 'addressProof')}

                                                    />
                                                    <div className='upload-content'>
                                                        <div className='upload-icon'>
                                                            <img src={uploadIcon} alt='upload' />
                                                        </div>
                                                        <h5 className='p-0'>Upload address proof</h5>
                                                        <p>File Should be png, jpeg, jpg.</p>
                                                    </div>

                                                </div>
                                                {watchFields?.addressProof && (
                                                    <p>
                                                        Selected File: {watchFields?.addressProof.name} ({(watchFields?.addressProof.size / 1024).toFixed(2)} KB)
                                                    </p>
                                                )}
                                            </div>
                                            {errors.addressProof?.message ? <span className={`invalid-feedback`} >{errors.addressProof?.message}</span> : null}
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='mb-4'>
                                            <div className={`${errors.selectedRegisterProof?.message && 'is-invalid'}`}>
                                                <DropDown
                                                    options={sebiProofOptions}
                                                    classNamePrefix="custom_select"
                                                    value={sebiProofDropValue}
                                                    name="role"
                                                    onChange={(e: any) => handleIdProofChange({ value: e, setDropValue: setSebiProofDropValue, field: 'selectedRegisterProof' })}
                                                />
                                            </div>
                                            {errors.selectedRegisterProof?.message ? <span className={`invalid-feedback`} >{errors.selectedRegisterProof?.message}</span> : null}
                                        </div>
                                        {
                                            sebiProofDropValue?.value === "SEBI_REGISTER" &&

                                            <div>
                                                <div className='inpt-item mb-4'>
                                                    <input type='text' id='sebinumber' className={`form-control ${errors.sebiNumber?.message && 'is-invalid'} ${watchFields?.sebiNumber?.length > 0 && 'valid-field'}`} value={watchFields?.sebiNumber} {...register('sebiNumber')}
                                                        onChange={(e) => {
                                                            setValue('sebiNumber', e.target.value)
                                                            if (e.target.value) {
                                                                clearErrors('sebiNumber')
                                                            }
                                                            else {
                                                                if (sebiProofDropValue?.value === "SEBI_REGISTER") setError('sebiNumber', { type: 'custom', message: 'SEBI Number is Required' });
                                                            }

                                                        }}
                                                    />
                                                    <label htmlFor='sebinumber' className='inpt-label'>SEBI Number</label>
                                                    {errors.sebiNumber?.message ? <span className={`invalid-feedback`} >{errors.sebiNumber?.message}</span> : null}
                                                </div>
                                                <div>
                                                    <div className={`${errors.sebiCertificate?.message && 'is-invalid'}`}>
                                                        <div
                                                            onDrop={(e) => handleDrop(e, 'sebiCertificate')}
                                                            onDragOver={preventDefault}
                                                            onDragEnter={preventDefault}
                                                            style={{
                                                                border: '2px dashed #ccc',
                                                                padding: '20px',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                            }}
                                                            className='upload-container'
                                                        >
                                                            <input
                                                                type="file"
                                                                onChange={(e) => handleFileInputChange(e, 'sebiCertificate')}

                                                            />
                                                            <div className='upload-content'>
                                                                <div className='upload-icon'>
                                                                    <img src={uploadIcon} alt='upload' />
                                                                </div>
                                                                <h5 className='p-0'>Upload SEBI Certificate</h5>
                                                                <p>File Should be png, jpeg, jpg.</p>
                                                            </div>

                                                        </div>
                                                        {watchFields?.sebiCertificate && (
                                                            <p>
                                                                Selected File: {watchFields?.sebiCertificate.name} ({(watchFields?.sebiCertificate.size / 1024).toFixed(2)} KB)
                                                            </p>
                                                        )}
                                                    </div>
                                                    {errors.sebiCertificate?.message ? <span className={`invalid-feedback`} >{errors.sebiCertificate?.message}</span> : null}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    addSignupRequestLoader ? <div className="load-more-button mt-3">
                        <SpinnerBtn />
                    </div> :
                        <div className='d-flex pt-3 kyc-gtn'>
                            <div>  <PrimaryButton btnType={'button'} btnName={'Back'} onClick={() => handleBack()} /></div>
                            <div className='d-flex'>
                                <OutlineButton btnName={'Clear'} btnType={`button`} onClick={() => handleClear()} />
                                <PrimaryButton btnName={'Next'} btnType={`button`} onClick={() => onSubmit(getValues())} />
                            </div>
                        </div>
                }
            </form>
        </div>
    );
};

export default KycDetails;