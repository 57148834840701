import ImageBlog from "../../../components/common/ImageBlog";
import missionVvision from "../../../assets/images/mission_vision.png";
import rippleIconPath from "../../../assets/images/svgicons/gps.svg";
import Vision from "../../../assets/images/svgicons/vision.svg";
import Mission from "../../../assets/images/svgicons/mission.svg";
import Aos from "aos";
import { useEffect } from "react";

const AboutVm = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-6" data-aos="fade-right">
                    <div className="ripple-content">
                        <ImageBlog
                            imagePath={missionVvision}
                            layout={2}
                            altName={"ripple-Image"}
                            rippleIcon={rippleIconPath}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="content-right">
                        <div className="content-vm" data-aos="fade-up">
                            <img src={Vision} alt="Vision-img" />
                            <div>
                                <h2>Our <span className="text-Gradient">Vision</span></h2>
                                <p>At FiDeal, we are dedicated to aiding Traders and Investors in their financial market endeavors through insightful and meticulously crafted suggestions. Our commitment lies in empowering aspiring traders and investors to attain success in their trades.</p>
                            </div>
                        </div>
                        <div className="content-vm mb-0" data-aos="fade-up">
                            <img src={Mission} alt="Mission-img" />
                            <div>
                                <h2>Our <span className="text-Gradient">Mission</span></h2>
                                <p>FiDeal is on a mission to emerge as India's leading portal for investors, traders, and MarketGenius. Our focus is to facilitate Indian traders and investors in achieving robust financial knowledge and by using it will achieve a profit margin proportional to their capital, fostering a sophisticated and organized investment community.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default AboutVm