import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import Header from "./Header";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useEffect, useLayoutEffect, useState } from "react";
// import Footer from "./Footer";

const ProtectedLoyout = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const accessToken = localStorage.getItem('auth-user');

    const [layout, setShowLayout] = useState(false);

    useEffect(() => {
        if (!location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    useEffect(() => {
        if (accessToken) {
            setShowLayout(true)
        } else {
            setShowLayout(false)
            navigate('/login')
        }
    }, [accessToken])

    return (
        <Outlet />
    );
};

export default ProtectedLoyout