import { createSlice } from "@reduxjs/toolkit";
import { emailVerifiyState } from "../../interfaces/auth";
import { emailVerifiyThunk } from "../../asyncThunks/auth";

const initialState: emailVerifiyState = {
  error: null,
  data: null,
  loading: false,
};

export const emailVerification = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(emailVerifiyThunk.pending, (state, action) => {
      state.error = null;
      state.data = null;
      state.loading = true;
    });
    builder.addCase(emailVerifiyThunk.fulfilled, (state, action) => {
      state.error = null;
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(emailVerifiyThunk.rejected, (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {  } = login.actions;

export default emailVerification.reducer;
