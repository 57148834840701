import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMarkettoMeter } from "../../helpers/backend_helper";


export const getMarkettoMeterThunk = createAsyncThunk(
    "GetMarkettoMater",
    async () => {
        const response = await getMarkettoMeter();
        return await response;
    }
);