import React from 'react';
import nodata from '../../assets/images/no-data.png'
const NoDataFound = ({ message }: any) => {
    return (
        <>
            <div className='no-data'>
                <img src={nodata} alt='No-data-found' />
                <p className='text-center text-bold'>{message}</p>
            </div>
        </>
    );
};

export default NoDataFound;