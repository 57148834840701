import React from "react";
import TipsCard from "../../../components/common/cards/TipsCard";
import Slider from "./Slider";
import LatestTips from "./LatestTips";
import MarkettoMeter from "./MarkettoMeter";

const HomeBanner = () => {
  return (
    <div>
      <div className="banner-bg">
        <div className="banner-group">
          <div className="h-100">
            <Slider />
          </div>
          <div className="mobile-blog">
            <MarkettoMeter />
            <LatestTips />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
