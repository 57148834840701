import React from "react";

const Address = () => {
  return (
    <div>
      <div className="ads-blog">
        <h5 className="foot-title">Address</h5>
        <p>
          Fideal,<br /> 76, Sify Infinite Spaces limited,<br /> Cyber Park, 3rd cross Road,<br /> Phase 1, Electronic city,<br /> Bangalore - 560100
        </p>
      </div>
    </div>
  );
};

export default Address;
