import React from 'react';
import { Modal } from 'reactstrap';
import PrimaryButton from '../buttons/PrimaryButton';
import OutlineButton from '../buttons/OutlineButton';
import closeIcon from '../../../assets/images/svgicons/close.svg';

const ConfirmationModal = ({ showModal, toggle, modalTitle, description, primaryBtn, secountBtn, primaryAction, outlineAction }: any) => {

    return (
        <Modal size="md" isOpen={showModal} centered={true}
            className='doc-modal create-folder'
            toggle={toggle}
        >
            <div className="modal-header border-0">
                <div className='head-item'>
                    <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                </div>
                <span onClick={toggle} aria-hidden="true" className='close-icon'>
                    <img src={closeIcon} alt="close" />
                </span>
            </div>
            <div className="modal-body">
                <div className='text-center'>
                    <p>{description}</p>
                </div>
                <div className='filter-btn d-flex justify-content-center my-4'>
                    <PrimaryButton btnName={primaryBtn} onClick={primaryAction} />
                    {secountBtn ?
                        <OutlineButton btnName={secountBtn} onClick={outlineAction} />
                        : ""}
                </div>

            </div>
        </Modal>
    );
};

export default ConfirmationModal;