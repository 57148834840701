import { createSlice } from "@reduxjs/toolkit";
import { signupState } from "../../interfaces/auth";
import { signupThunk } from "../../asyncThunks/auth";

const initialState: signupState = {
  error: null,
  data: null,
  loading: false,
};

export const signup = createSlice({
  name: "signup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signupThunk.pending, (state, action) => {
      state.error = null;
      state.data = null;
      state.loading = true;
    });
    builder.addCase(signupThunk.fulfilled, (state, action) => {
      state.error = null;
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(signupThunk.rejected, (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.loading = false;
    });
  },
});



export default signup.reducer;
