import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import { useAppDispatch } from "../../store/store";
import { forgotPswThunk } from "../../store/asyncThunks/auth";
import { useNavigate } from "react-router";
import Applogo from '../../assets/images/white-logo.svg';
import { NavLink } from "react-router-dom";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import loginImage from '../../assets/images/login-image.png';
import pswShow from '../../assets/images/svgicons/eye.svg';
import pswHide from '../../assets/images/svgicons/eye-off.svg';
import { useSelector } from "react-redux";
import SpinnerBtn from "../../components/common/buttons/SpinnerBtn";
import homeIcon from "../../assets/images/svgicons/home.svg";


const ForgotPassword = () => {
    document.title = "Forgot Password | FiDeal";

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [watchFields, setWatchFields] = useState<any>();

    const loading = useSelector((state: any) => state?.forgotPasswordReducer?.loading)

    const forgotpswForm = {
        email: "",
    };

    const forgotpswFormSchema = Yup.object().shape({
        email: Yup.string().required("Enter email address").test('email', 'Enter valid email address', val => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
            else return true
        }),
    });

    const { register, handleSubmit, formState: { errors, }, watch } = useForm({
        defaultValues: forgotpswForm,
        resolver: yupResolver(forgotpswFormSchema),
    });

    const navigateAfterSignup = () => {
        navigate(`/reset-password`);
        // navigate(urlpath);

    };

    const onSubmit = (values: any) => {
        const data: any = {};
        data["values"] = values;
        data["navigate"] = navigateAfterSignup;
        dispatch(forgotPswThunk(data));
    };
    const handleWheel = (event: any) => {
        event.preventDefault(); // Prevent the default scrolling behavior
    };
    useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            setWatchFields(value)
        )
        return () => subscription.unsubscribe()
    }, [watch])
    return (
        <div className="auth-screen">
            <div className="auth-overlayshape"></div>
            <div className="app-logo">
                <NavLink to={'/'}> <img src={Applogo} alt="Applogo" /></NavLink>
            </div>
            <NavLink to={'/'}>
                <div className="backto-home" >
                    <img src={homeIcon} alt="homeIcon" />
                </div>
            </NavLink>
            <div className="row">
                <div className="col-lg-5">
                    <div className="cont-bg">
                        <div className="ripple-small"></div>
                        <figure>
                            <img src={loginImage} alt="login-img" />
                        </figure>
                        <div className="ripple-small right"></div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="auth-form">
                        <div className="welcome-cont">
                            <h2>Forgot Password</h2>
                            <p>Enter your email for the verification process. We will send 4 digits code to your email.</p>

                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                            <div className='inpt-item mb-4'>
                                <input type='text' id='email' {...register("email")} className={`${errors.email?.message ? 'is-invalid ' : ''} form-control ${watchFields?.email?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='email' className='inpt-label'>Email address<span>*</span></label>
                                {errors.email?.message ? <span className={`invalid-feedback`} >{errors.email?.message}</span> : null}
                            </div>

                            <div className="auth-btn">
                                {loading ? <SpinnerBtn /> :
                                    <PrimaryButton btnName="Send code" btnType="submit" />}
                            </div>
                        </form>
                        <div className="back-to">
                            <p> Go back to <NavLink to={'/login'}>Sign In</NavLink></p>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    );
};

export default ForgotPassword;
