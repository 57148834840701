import "./assets/scss/app.scss";
import 'react-loading-skeleton/dist/skeleton.css';
import "aos/dist/aos.css";
import Routing from "./routing/Routing";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { WebsocketProvider, socket } from "./constants/socket/socket";
import Notification from "./components/header/component/Notification";
import { useEffect } from "react";
import { decodeToken } from "./store/slices/auth/decodedToken";
import { useAppDispatch, useAppSelector } from "./store/store";
import { jwtDecode } from "jwt-decode";
import { getProfileImgThunk } from "./store/asyncThunks/Profile";

function App() {

  const dispatch = useAppDispatch();

  const decodedData = useAppSelector(state => state.decodeTokenReducer.data);
  const accessToken = localStorage.getItem('auth-user');

  useEffect(() => {
    if (accessToken) {
      const decoded: any = jwtDecode(accessToken);
      dispatch(decodeToken(decoded));

    } else {
      dispatch(decodeToken(null));
    }
  }, [accessToken])

  useEffect(() => {
    if (decodedData) {
      if (decodedData?.profile) {
        dispatch(getProfileImgThunk({ key: decodedData?.profile }))
      }
    }
  }, [decodedData])

  return (
    <>
      <WebsocketProvider value={socket}>
        <Routing />
      </WebsocketProvider>
      <Toaster position="bottom-center" reverseOrder={false} toastOptions={{
        success: {
          iconTheme: {
            primary: '#fff',
            secondary: '#34c38f',
          },
          style: {
            background: '#34c38f',
            color: '#fff',
          },

        },
        error: {
          iconTheme: {
            primary: 'white',
            secondary: '#f46a6a',
          },
          style: {
            background: '#f46a6a',
            color: '#fff',
          },
        },
      }} />
    </>

  )

}

export default App;
