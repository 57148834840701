import React from "react";
import whiteLogo from "../../../assets/images/white-logo.svg";

const FooterLogo = () => {
  return (
    <>
      <div className="logo-part">
        <img src={whiteLogo} alt="app-logo" />
        <p className="pt-2">
          Regular market trend Updates, More Accuracy GeniusTalk Daily Option, Expiry Option Focused GeniusTalk
        </p>
      </div>
    </>
  );
};

export default FooterLogo;
