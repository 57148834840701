import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TipsCardSkeletons = () => {
    return (
        <>
            <div className="tips-item">
                <div className="tips-item-header">
                    <div className="d-flex justify-content-between">
                        <div className="cat-type">
                            <span className='d-flex'> <Skeleton height={"16px"} width={'80px'} />&nbsp;&nbsp;<Skeleton height={"16px"} width={'50px'} /></span>
                            <span className='d-flex mt-2'> <Skeleton height={"10px"} width={'50px'} /></span>
                        </div>
                        <div>
                            <div className="d-flex">
                                <div className="typ-stus">
                                    <Skeleton height={"25px"} width={'55px'} borderRadius={'50px'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tip-cont">
                    <p className='pt-2'>
                        <Skeleton height={"16px"} width={'100%'} />
                        <Skeleton height={"16px"} width={'80%'} />
                        <Skeleton height={"16px"} width={'60%'} />
                    </p>
                </div>
                <div className="d-flex justify-content-between align-items-center ">
                    <div className="advisor-info">
                        <div className="advisor-pic">
                            <Skeleton height={"25px"} width={'25px'} borderRadius={'50px'} />
                        </div>
                        <h6><Skeleton height={"18px"} width={'120px'} /></h6>
                    </div>
                    <div className="likes">
                        <ul className="d-flex align-items-center g-2">
                            <li className='d-flex'>
                                <span><Skeleton height={"20px"} width={'20px'} /></span>
                                &nbsp;&nbsp;
                                <span><Skeleton height={"20px"} width={'20px'} /></span>
                            </li>
                            <li className='d-flex'>
                                <span><Skeleton height={"20px"} width={'20px'} /></span>
                                &nbsp;&nbsp;
                                <span><Skeleton height={"20px"} width={'20px'} /></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TipsCardSkeletons;