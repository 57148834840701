import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CopyRights from "./component/CopyRights";
import FooterLogo from "./component/FooterLogo";
import FooterMenu from "./component/FooterMenu";
import Address from "./component/Address";
import ContactBlog from "./component/ContactBlog";
import SocialMedia from "./component/SocialMedia";
import Aos from "aos";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="page-footer">
      <div className="container">
        <ContactBlog />
        <div className="foot-one">
          <FooterLogo />
          <FooterMenu />
          <Address />
        </div>
        <hr />
        <div className="disclaimer">
          <p><span>DISCLAIMER: </span> We are NOT SEBI registered Advisors; we only allow sharing ideas and financial-related information from one to another trading community based on their practical trading experience. Kindly take the trades according to your risk and reward position and consult your advisor. It is advisable to take the advice of SEBI-registered advisors before entering into any of your trades. All posts/calls/tips/views/information shared are just for Educational purposes only. Non-Advisory, Discretional, No Claims, Rights reserved. We are not responsible for your losses. Consult your financial advisor before taking any position.</p>
        </div>
        <hr />
        <div className="row copy-right">
          <div className="col-lg-6">
            <CopyRights />
          </div>
          <div className="col-lg-6">
            {/* <SocialMedia /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
