import Select from "react-select";

const DropDown = (props: any) => {
  const classNamePrefix = `custom_select ${props?.classNamePrefix || ""}`;
  const mainClass = `position-relative drop-down ${props?.mainClass || ""}`;

  return (
    <div className={mainClass}>
      <Select
        options={[{ label: " Select", value: "" }]}
        classNamePrefix={classNamePrefix}
        placeholder={`Select`}
        {...props}
      />
    </div>
  );
};

export default DropDown;
