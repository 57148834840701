import StarRatings from "react-star-ratings";
interface types {
  rating: number;
}

const ViewRating = ({ rating }: types) => {
  return (
    <StarRatings
      rating={Number(rating)}
      starRatedColor="#FCBD00"
      numberOfStars={5}
      name="rating"
      starEmptyColor={"#fff"}
    />
  );
};

export default ViewRating;
