import { Link } from "react-router-dom";
import location from "../../../assets/images/svgicons/location.svg";
import email from "../../../assets/images/svgicons/email.svg";
import phone from "../../../assets/images/svgicons/phone.svg";
import insta from "../../../assets/images/svgicons/social-icons/Instagram.svg";
import fb from "../../../assets/images/svgicons/social-icons/Facebook.svg";
import twitter from "../../../assets/images/svgicons/social-icons/twitter.svg";
import ln from "../../../assets/images/svgicons/social-icons/linkedin.svg";
import { useAppSelector } from "../../../store/store";
import { useDispatch } from "react-redux";
import { getalSettingsThunk } from "../../../store/asyncThunks/Settings";
import { useEffect } from "react";

const ContactInfo = () => {
  const dispatch = useDispatch<any>();
  const settingsInfo = useAppSelector((state: any) => state?.appSettingsData?.data?.settings?.[0]);

  useEffect(() => {
    dispatch(getalSettingsThunk());
  }, []);

  return (
    <div className="content-left">
      <div className="address">
        <h1>Contact Info</h1>
        <div className="details" data-aos="fade-up">
          <div className="address-logo">
            <img src={location} alt="location" />
          </div>
          <div className="address-landmark">
            <p>
              Fideal, <br /> 76, Sify Infinite Spaces limited,<br /> Cyber Park, 3rd cross Road,<br /> Phase 1, Electronic city,<br /> Bangalore - 560100.

              {/* <span className="pt-0 d-block">Pincode - 999000</span> */}
            </p>
          </div>
        </div>
        {settingsInfo && settingsInfo?.length === 0 ? ('') :
          <div className="details" data-aos="fade-up">
            <div className="address-logo">
              <img src={phone} alt="phone" />
            </div>
            <div className="address-landmark">
              <p>
                <Link to={`tell: +91 ${settingsInfo?.mobileNumber}`}>{`+91 ${settingsInfo?.mobileNumber}`}</Link>
              </p>
            </div>
          </div>
        }
        {settingsInfo && settingsInfo?.length === 0 ? ('') :
          <div className="details" data-aos="fade-up">
            <div className="address-logo">
              <img src={email} alt="email" />
            </div>
            <div className="address-landmark">
              <p>
                <Link to={`mailto: ${settingsInfo?.email}`}>{settingsInfo?.email}</Link>
              </p>
            </div>
          </div>
        }


        {/* <div className="socialmedia" data-aos="fade-up">
          <p>Social Media</p>
          <div className="social-logo">
            <Link to="">
              <img src={insta} alt="instagram" />
            </Link>
            <Link to="">
              <img src={ln} alt="linked_in" />
            </Link>
            <Link to="">
              <img src={twitter} alt="twitter" />
            </Link>
            <Link to="https://www.facebook.com/">
              <img src={fb} alt="facebook" />
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContactInfo;
