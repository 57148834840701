import React from "react";

const ServicesCard = ({ item, key }: any) => {
  return (
    <>
      <div className="sers-items" key={key}>
        <div className="text-center">
          <div className="icon-large">
            <img src={item?.icon} alt={item?.title} />
          </div>
          <h4 className="pt-3">{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    </>
  );
};

export default ServicesCard;
