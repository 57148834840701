import React, { useEffect, useState } from "react";
import TipsCard from "../../components/common/cards/TipsCard";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import { dropOptions } from "../../constants/data/BuySellOptions";
import DropDown from "../../components/common/DropDown";
import AccordionMenu from "../../components/common/AccordionMenu";
import Banner from "../../components/common/banners/Banner";
import tipsBanner from "../../assets/images/tips-banner.png";
import refresh from "../../assets/images/svgicons/refresh-icon.svg";
import SearchInput from "../../components/common/SearchInput";
import { nameOptions } from "../../constants/data/NameOptions";
import AdvisorsCard from "../../components/common/cards/AdvisorsCard";
import { useDispatch, useSelector } from "react-redux";
import { getTopAdvisorThunk } from "../../store/asyncThunks/Advisors";
import { getTipsThunk } from "../../store/asyncThunks/Tips";
import { getCategoryThunk } from "../../store/asyncThunks/Category";
import RadioButton from "../../components/common/buttons/RadioButton";
import { dateOptions } from "../../constants/data/DateOptions";
import arrowDown from "../../assets/images/svgicons/arrow-down.svg";
import { resetData } from "../../store/slices/tips/getTips";
import TipsCardSkeletons from "../../components/common/skeleton/TipsCardSkeletons";
import NoDataFound from "../../components/common/NoDataFound";
import AccordianMenuSkeleton from "../../components/common/skeleton/AccordianMenuSkeleton";
import OptionsSkeleton from "../../components/common/skeleton/OptionsSkeleton";
import DateDrop from "../../components/common/skeleton/DateDrop";
import ButtonSkeleton from "../../components/common/skeleton/ButtonSkeleton";
import { updateNotification } from "../../store/slices/tips/notification";
import LoadMoreSpinner from "../../components/common/LoadMoreSpinner";
import SpinnerBtn from "../../components/common/buttons/SpinnerBtn";
import { Helmet } from "react-helmet";
import Aos from "aos";

const Tips = () => {
  const dispatch = useDispatch<any>();

  const TopAdvisorList = useSelector(
    (state: any) => state.topAdvisorReducer?.data?.advisors
  );
  const getTipsList = useSelector((state: any) => state?.getTipsReducer?.data);
  const getTipsLoading = useSelector(
    (state: any) => state?.getTipsReducer?.loading
  );
  const getCategoryList = useSelector(
    (state: any) => state?.getCategoryReducer?.data?.category
  );
  const getCategoryLoading = useSelector(
    (state: any) => state?.getCategoryReducer?.loading
  );

  const limit = 9;
  const dataPerPage = process.env.REACT_APP_DATA_PERPAGE_TIPS || 14;

  // const [tokenValue, setTokenValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [canAddData, setCanAddData] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const [date, setDate] = useState({ label: "Today", value: 0 });
  const [openFilter, setOpenFilter] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({
    dateFilter: 0,
    guide: null, // selected advisors id in []
    subService: null, // selected sub-categories id in []
    tip: null, // buy or sell
  });

  const handleChange = (event: any) => {
    const newFilter = {
      ...filter,
      tip: event.target.value === "ALL" ? null : event.target.value,
    };
    setFilter(newFilter);
  };

  const isChecked = (value: any) => {
    if (!filter?.tip && value == "ALL") {
      return true;
    } else {
      return filter?.tip === value;
    }
  };

  const getTopAdvisors = () => {
    dispatch(getTopAdvisorThunk(limit));
  };

  const handleAddMergeData = () => {
    if (getTipsList?.data?.length > 0) {
      const newData = [...listData, ...getTipsList?.data];
      setListData(newData);
      if (newData?.length === getTipsList?.totalCount) {
        setCanAddData(false);
      } else {
        setCanAddData(true);
      }
    }
  };

  const getTips = (payload: any) => {
    const data: any = {};
    data["payload"] = payload;
    data["setOpenFilter"] = setOpenFilter;
    data["setFilter"] = setFilter;
    data["currentPage"] = currentPage;
    data["dataPerPage"] = dataPerPage;
    dispatch(getTipsThunk(data));
    // const tipstoredValue = localStorage.getItem('auth-user');
    // if (tipstoredValue) {
    //   setTokenValue(tipstoredValue);
    // }
  };

  useEffect(() => {
    dispatch(updateNotification(false)); // to reset notification when tips page opened
    getTopAdvisors();
    dispatch(getCategoryThunk());
    resetList();
  }, []);

  const handleDateFilter = (value: any) => {
    const newFilter = { ...filter, dateFilter: value?.value };
    setFilter(newFilter);
    setDate(value);
  };

  const handleApply = async () => {
    setOpenFilter(false);
    // if (showFilter) {
    //   await resetList();
    // getTips(filter);
    // }
  };

  // TO-DO future
  //to check the scroll is reached last or not
  // const handleScroll = async () => {
  //   const innerBodyHeight: any = document.querySelector(".advisor-view");
  //   if (listData?.length != getTipsList?.totalCount && canAddData) {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop <
  //       innerBodyHeight.offsetHeight ||
  //       getTipsLoading
  //     ) {
  //       return;
  //     }

  //     const newPage = currentPage + 1;
  //     setCurrentPage(newPage);
  //   }
  // };

  //starting scroll eventlistener
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [getTipsLoading, canAddData]);

  useEffect(() => {
    handleAddMergeData();
  }, [getTipsList?.data]);

  useEffect(() => {
    getTips(filter);
  }, [currentPage, filter]);

  function resetList() {
    dispatch(resetData());
    setListData([]);
    setCurrentPage(1);
  }

  useEffect(() => {
    // if (showFilter == false) {
    const handleShowTips = async () => {
      await resetList();
    };
    handleShowTips();
    // }
  }, [filter]);

  useEffect(() => {
    let width = window.innerWidth;
    if (width <= 1220) {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
  }, []);

  const handleLoadMore = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
  }
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="advisor-view tips">
      <Helmet>
        <title>GeniusTalk | FiDeal</title>
      </Helmet>
      <Banner
        title="GeniusTalk"
        description={`Access enhanced precision discussions, view sharing, knowledge sharing, and ideas between the big community of traders with fundamental-focused positional insights, and technical trading focus for optimal decision-making.`}
        imagePath={tipsBanner}
      />
      <section
        className={`large-cap tips-section ${openFilter ? "open-filter" : ""}`}
      >
        <div className="filter-icon">
          <div
            className="filter-toggle"
            onClick={() => setOpenFilter(!openFilter)}
          >
            <button className="filter-open">
              <img src={arrowDown} alt="" />
            </button>
            <button className="filter-close">
              <img src={arrowDown} alt="" />
            </button>
          </div>
        </div>

        <div className="container">
          <div className="tips-details">
            <div className="row">
              <div className="col-lg-4 tips-filter" data-aos="fade-in">
                {getCategoryLoading == true ? (
                  <div className="accordion-menu">
                    {[...Array(3)].map((index) => {
                      return <AccordianMenuSkeleton key={`advisor${index}`} />;
                    })}
                  </div>
                ) : (
                  <AccordionMenu
                    categories={getCategoryList}
                    values={filter}
                    setValues={setFilter}
                  />
                )}

                <div className="filter-card">
                  {getTipsLoading == true && getCategoryLoading ? (
                    <DateDrop />
                  ) : (
                    <div className="date-filter">
                      <h5 className="title sm">Date</h5>
                      <DropDown
                        options={dateOptions}
                        classNamePrefix="custom_select"
                        // search={true}
                        value={date}
                        name="role"
                        placeholder={`Select date`}
                        onChange={handleDateFilter}
                        mainClass="md"
                      />
                    </div>
                  )}

                  {getTipsLoading == true && getCategoryLoading ? (
                    <OptionsSkeleton />
                  ) : (
                    <div className="options">
                      <h5 className="title sm">Options</h5>
                      <div className="radio-group">
                        {dropOptions?.map((item: any, index: number) => {
                          const { value, label } = item;
                          return (
                            <RadioButton
                              key={index}
                              id={value}
                              name="options"
                              value={value}
                              text={label}
                              onChange={handleChange}
                              checked={isChecked(value)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className="top-advisors heading">
                  <h5 className="title">Preferred MarketGenius</h5>
                  <div className="tips-group">
                    {TopAdvisorList?.map((advisors: any) => (
                      <AdvisorsCard advisors={advisors} key={advisors._id} />
                    ))}
                  </div>
                </div>
                {showFilter && (
                  <div className="btn-action">
                    {getTipsLoading == true ? (
                      <ButtonSkeleton />
                    ) : (
                      <PrimaryButton
                        btnName={"Apply"}
                        onClick={() => handleApply()}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-8 tip-card">
                {/* TO-DO */}
                {/* <div className="filter">
                  <div className="refresh">
                    <img src={refresh} alt="refresh" />
                    <p className="ms-2">Last Refreshed 3m ago</p>
                  </div>
                </div> */}

                {getTipsLoading && currentPage === 1 &&
                  <div className="tips-group pt-0 mt-0">
                    {[...Array(6)]?.map((item: any, index: number) => {
                      return <TipsCardSkeletons key={`tip-card-skeleton-${index}`} />
                    })}
                  </div>
                }
                {listData && listData?.length > 0 && <div className="tips-group pt-0 mt-0" data-aos="fade-up">
                  {listData?.map((tips: any) => (
                    <TipsCard tips={tips} key={tips._id} listData={listData} setListData={setListData} />
                  ))}
                </div>
                }
                {(listData?.length === 0) && getTipsLoading === false && <NoDataFound message={'GeniusTalk Not Found'} />}
                {/* {getTipsLoading === true && <div className="mt-5"><LoadMoreSpinner /></div>} */}

                {listData?.length != getTipsList?.totalCount &&
                  <div className="load-more-button mt-5">
                    {
                      getTipsLoading ? <SpinnerBtn /> :
                        <PrimaryButton btnName="Load More" btnType='button' onClick={() => handleLoadMore()} />
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tips;
