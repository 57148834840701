import ImageBlog from "./ImageBlog"
import { Key, useEffect } from "react";
import tick from "../../assets/images/svgicons/tick.svg";
import Aos from "aos";

const ServiceTypes = ({ customClass, ServicesData, rippleIconPath, layoutStyle, rippleImage }: any) => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <>
            <div className={`row w-100 ${customClass && customClass}`}>
                <div className='col-lg-6' data-aos="fade-up">
                    <h2> <span className="text-Gradient">{ServicesData?.title}</span> </h2>
                    <p>{ServicesData?.description}</p>
                    {ServicesData?.descriptionTwo ? <p>{ServicesData?.descriptionTwo}</p> : ""}
                    <ul className="list-container">
                        {ServicesData?.explanation?.map((item: { optionDes: string, icon: string, optionHead: string }, index: Key) => (
                            <li key={index} className="list-item">
                                <div>
                                    <div className="tick-image">  <img src={item?.icon} alt="tick" /></div>
                                </div>
                                <div>
                                    <h4>{item?.optionHead}</h4>
                                    <p>{item?.optionDes}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-lg-6 ">
                    <div className="ripple-content" data-aos="fade-up">
                        <ImageBlog
                            imagePath={rippleImage}
                            layout={layoutStyle}
                            altName={"ripple-Image"}
                            rippleIcon={rippleIconPath}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ServiceTypes;