import { useState } from 'react';
import editIcon from '../../../assets/images/svgicons/edit-icon.svg'
import toast from 'react-hot-toast';
import { getProfileImgThunk, updateProfileImageThunk } from '../../../store/asyncThunks/Profile';
import { useAppDispatch } from '../../../store/store';
import { jwtDecode } from 'jwt-decode';
import { decodeToken } from '../../../store/slices/auth/decodedToken';

interface props {
    user: any;
    imagePath: string;
}

const ProfileImage = (props: props) => {
    const { user, imagePath } = props;

    const dispatch = useAppDispatch();

    const [resetInput, setResetInput] = useState(false)
    setTimeout(() => {
        if (resetInput === true) setResetInput(false)
    }, 0);

    const afterUpdateImage = (token: any) => {
        localStorage.setItem("auth-user", token);
        const decoded: any = jwtDecode(token);
        dispatch(decodeToken(decoded));
        if (decoded?.profile) {
            dispatch(getProfileImgThunk({ key: decoded?.profile }))
        }
    }

    const handleProfileImg = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const size = e.target.files[0].size / 1024 / 1024;
            if (size < 1) {
                if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/png') {
                    const data: any = {};
                    data['payload'] = { files: e.target.files };
                    data['afterUpdateImage'] = afterUpdateImage;
                    dispatch(updateProfileImageThunk(data));
                } else {
                    toast.error("Supported file formats are PNG or JPEG")
                    setResetInput(true)
                }
            } else {
                toast.error("Max allowed file size is 1 MB")
                setResetInput(true)
            }
        }
    }

    return (
        <div className="common-banner profile-banner">
            <div className="banner-bg">
                <div className="container">
                    <div className="text-container">

                        <div className="image-wrapper">
                            <div className="breathing-circle">
                                {/* <img src={imagePath} alt="banner-image" /> */}
                                <div className="profile-edit">
                                    <img src={imagePath} alt="avatar" className="avatar-md rounded-circle img-thumbnail" />
                                    {resetInput == false ? <input type="file" id="profileUpload" accept="image/jpg,image/jpeg,image/png" name="profileUpload" hidden
                                        onChange={(e) => handleProfileImg(e)} /> : ""}
                                    <label htmlFor="profileUpload" className="m-0 edit-icon">
                                        <img src={editIcon} alt='edit' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="text-wrapper">
                            <h1 className="title">{user?.firstName}</h1>
                            <p className="description">{user?.email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileImage;
