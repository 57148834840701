import React from "react";
import Skeleton from "react-loading-skeleton";

const ButtonSkeleton = () => {
  return (
    <>
      <div>
        <Skeleton height={"45px"} width={"160px"} borderRadius={"30px"} />
      </div>
    </>
  );
};

export default ButtonSkeleton;
