import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ViewAdvisorSkeleton = () => {
    return (
        <div className="text-container view-advisor-banner">

            <div className="col-lg-3">
                <div className="image-wrapper">
                    <div className="breathing-circle">
                        <Skeleton height={"200px"} width={'200px'} borderRadius={'50%'} baseColor={'#3db14cd4'} highlightColor={'#f5f5f557'} />
                    </div>
                </div>
            </div>
            <div className="col-lg-9">
                <div className="text-wrapper">
                    <div className="view-advisor">
                        <div className="advisor-info">
                            <h1 className="title"><Skeleton height={"23px"} width={'300px'} baseColor={'#3db14cd4'} highlightColor={'#f5f5f557'} /></h1>
                            <div className="rating-grp">
                                <Skeleton height={"18px"} width={'100px'} borderRadius={'30px'} baseColor={'#3db14cd4'} highlightColor={'#f5f5f557'} />
                                <h6 className="d-flex align-items-center"><Skeleton height={"15px"} width={'130px'} baseColor={'#3db14cd4'} highlightColor={'#f5f5f557'} /></h6>
                            </div>
                            <div className="view-description">
                                <p className="description p-0">
                                    <Skeleton height={"15px"} width={'300px'} baseColor={'#3db14cd4'} highlightColor={'#f5f5f557'} />
                                    <Skeleton height={"15px"} width={'250px'} baseColor={'#3db14cd4'} highlightColor={'#f5f5f557'} />
                                    <Skeleton height={"15px"} width={'200px'} baseColor={'#3db14cd4'} highlightColor={'#f5f5f557'} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewAdvisorSkeleton;