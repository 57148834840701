import { createSlice } from "@reduxjs/toolkit";
import { notificationInterface } from "../../interfaces/tips";

const initialState: notificationInterface = {
    data: false
}

export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState,
    reducers: {
        updateNotification: ((state, action) => {
            state.data = action.payload;
        }),
    }
})

export const { updateNotification } = notificationSlice.actions;

export default notificationSlice.reducer;