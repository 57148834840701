import React from "react";
import Skeleton from "react-loading-skeleton";

const AccordianMenuSkeleton = () => {
  return (
    <>
      <div className="accordion-item card ">
        <div className="accordion-header">
          <div className="d-flex align-items-center">
            <div>
              <div className="user-info">
                <span>
                  <Skeleton height={"20px"} width={"120px"} />
                </span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Skeleton height={"20px"} width={"30px"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordianMenuSkeleton;
