import { createAsyncThunk } from "@reduxjs/toolkit";
import { addRating, reviewRating } from "../../helpers/backend_helper";
import toast from "react-hot-toast";

export const addRatingThunk = createAsyncThunk(
    "addUserRating/addRatingThunk",
    async (props: any) => {
        const { data, afterSuccess } = props
        try {
            const response = await addRating(data);
            if (response?.status?.toLowerCase() === "success") {
                afterSuccess(true);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error) {
            toast.error("An error occurred while adding the rating.")
        }
    }
);
export const reviewRatingThunk = createAsyncThunk(
    "reviewRating/reviewRatingThunk",
    async ({ AdvisorId, currentPage, pageSize }: any) => {
        const response = await reviewRating(currentPage, pageSize, { id: AdvisorId });
        return response;
    }
);