//LOGIN
export const LOGIN = "/end-user/login";
//Add New User
export const SIGNUP = "/end-user/add";
//Email Verifiy
export const EMAIL_VERIFIY = "/end-user/token-check";
export const EMAIL_VERIFIY_RESENT = "/end-user/resend-otp";
export const FORGOT_PASSWORD = "/end-user/forgot-password";
export const FORGOT_PASSWORD_RESET = "/end-user/forgot-password-reset";
//Advisor
export const GET_ADVISOR= "/user/advisor/get-all/0/0"
//Top Advisor 
export const GET_TOP_ADVISOR="/user/advisor/top"
//Tips List
export const GET_TIPS = "/user/tips/get-all"
//Recent Tips
export const GET_RECENT_TIPS="/user/tips/recent"
//Get Advisor By Id
export const GET_ADVISOR_BY_ID="/user/advisor"
//Get Tips By Advisor Id
export const GET_TIPS_BY_ADVISOR="/user/tips/get-tip"
//Add User Rating
export const ADD_RATING="/end-user/rating/add"
//Get Faq
export const GET_FAQ="/user/faq/get-all/0/0"
//Get Category
export const GET_CATEGORY="/user/category/get-all"
//Get Tips By Advisor Filter
export const GET_TIPS_BY_ADVISOR_FILTER="user/tips/get-all/0/9"
//Review Card Pagination
export const REVIEW_RATING="user/advisor/rating-request"
//Contact Us
export const CONTACT_US="end-user/enquiry"
//Marketo Mater
export const GET_MARKETTO_METER="marketto-meter/userslot"
export const UPDATE_LIKES="end-user/like"
//signup request
export const ADD_SIGNUP_REQUEST="admin/signup-request"
//profile
export const UPDATE_PROFILE="end-user/update-profile"
export const UPDATE_PASSWORD="/end-user/profile/change-pwd"
export const UPDATE_PROFILE_IMAGE="/end-user/update-profile-img"
export const GET_PROFILE_IMAGE="/end-user/get-profile-img"

// Settings
export const GET_Settings = "/user/settings/get-all"