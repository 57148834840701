import React from 'react';
import MyAccount from './component/MyAccount';
import { useAppSelector } from '../../store/store';
import ChangePassword from './component/ChangePassword';
import ProfileImage from './component/ProfileImage';
import Banner from '../../components/common/banners/Banner';
import avatar from '../../assets/images/avatar.png'
import { Helmet } from 'react-helmet';

const MyProfile = () => {

    const user = useAppSelector(state => state.decodeTokenReducer?.data)
    const imagePath = useAppSelector(state => state.getProfileImgReducer?.data?.file)

    return (
        <>
            <Helmet>
                <title>My Profile | FiDeal</title>
            </Helmet>
            <ProfileImage
                imagePath={imagePath ? imagePath : avatar}
                user={user}
            />
            <section className='large-cap'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='my-profile'>
                                <MyAccount user={user} />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='my-profile'>
                                <ChangePassword />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MyProfile;