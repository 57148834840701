import fullStar from "../../../assets/images/svgicons/full_star.svg";

interface stars {
  stars?: any;
  ratingCount?: any;
  reviewCount?: string | number;
}

const RatingProgress = (props: stars) => {
  const { stars, ratingCount, reviewCount } = props;

  const totalRatings = stars?.one + stars?.two + stars?.three + stars?.four + stars?.five;

  const percentageOne = (stars?.one / totalRatings) * 100 || 0;
  const percentageTwo = (stars?.two / totalRatings) * 100 || 0;
  const percentageThree = (stars?.three / totalRatings) * 100 || 0;
  const percentageFour = (stars?.four / totalRatings) * 100 || 0;
  const percentageFive = (stars?.five / totalRatings) * 100 || 0;


  return (
    <div className="rating col-md col-lg-4">
      <div className="star-list  star-5">
        <div className="count-star">
          <div>5</div>
          <div className="star-image">
            <img src={fullStar} alt="star" />
          </div>
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percentageFive}%` }}
          ></div>
        </div>
      </div>
      <div className="star-list  star-4">
        <div className="count-star">
          <div>4</div>
          <div className="star-image">
            <img src={fullStar} alt="star" />
          </div>
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percentageFour}%` }}
          ></div>
        </div>
      </div>
      <div className="star-list  star-3">
        <div className="count-star">
          <div>3</div>
          <div className="star-image">
            <img src={fullStar} alt="star" />
          </div>
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percentageThree}%` }}
          ></div>
        </div>
      </div>
      <div className="star-list  star-2">
        <div className="count-star">
          <div>2</div>
          <div className="star-image">
            <img src={fullStar} alt="star" />
          </div>
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percentageTwo}%` }}
          ></div>
        </div>
      </div>
      <div className="star-list  star-1">
        <div className="count-star">
          <div>1</div>
          <div className="star-image">
            <img src={fullStar} alt="star" />
          </div>
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percentageOne}%` }}
          ></div>
        </div>
      </div>

      <div className="overall-rating">
        <div className="rating-count">
          <div className="overall-count">
            <h1>{ratingCount || 0}</h1>
            <div className="star-image">
              <img src={fullStar} alt="star" />
            </div>
          </div>
          <p>{reviewCount || 0} Reviews</p>
        </div>
        {/* <div className="rating-percentage">
          <div className="overall-count">
            <h1>88</h1>
            <span>%</span>
          </div>
          <p>Recommended</p>
        </div> */}
      </div>
    </div>
  );
};

export default RatingProgress;
