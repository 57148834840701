import Banner from "../../components/common/banners/Banner";
import advisor from "../../assets/images/advisor-ban.png";
import AdvisorsCard from "../../components/common/cards/AdvisorsCard";
// import Select from "react-select";
import SearchInput from "../../components/common/SearchInput";
import DropDown from "../../components/common/DropDown";
import { nameOptions } from "../../constants/data/NameOptions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getadvisorThunk } from "../../store/asyncThunks/Advisors";
import AdvisorsCardSkeleton from "../../components/common/skeleton/AdvisorsCardSkeleton";
import { debounce } from "lodash"
import { Helmet } from "react-helmet";
import NoDataFound from "../../components/common/NoDataFound";
import Aos from "aos";

const AdvisorList = () => {


  const dispatch = useDispatch<any>();

  const getAdvisorList = useSelector((state: any) => state.getAdvisorReducer?.data?.advisors)
  const getAdvisorLoading = useSelector((state: any) => state?.getAdvisorReducer?.loading)

  useEffect(() => {
    dispatch(getadvisorThunk({ search: "" }));
  }, []);

  const handleSearchChange = debounce((searchValue: string) => {
    dispatch(getadvisorThunk({ search: searchValue }));
  }, 1000);
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Helmet>
        <title>Advisors | FiDeal</title>
      </Helmet>
      <div className="advisors-list">
        <Banner
          title="MarketGenius"
          description={`Discover a unified platform showcasing top-class successful trading community people who are willing to share their knowledge and ideas with other traders. We can call them as MarketGenius. Choose your preferred MarketGenius and access their views, all in one convenient portal.`}
          imagePath={advisor}
        />
        <section className="large-cap">
          <div className="container">
            <div className="filter">
              <div data-aos="fade-left">
                <SearchInput onSearchChange={handleSearchChange} />
              </div>
              {/* <div>
                <DropDown
                  options={nameOptions}
                  classNamePrefix="custom_select"
                  // search={true}
                  // value={defaultValue}
                  name="role"
                  placeholder={`Select`}
                  onChange={(value: any) => {
                  }}
                  mainClass="md"
                />
              </div> */}
            </div>
            {!getAdvisorLoading === false ?
              <>
                <div className="tips-group">
                  {[...Array(15)].map((index) => {
                    return <AdvisorsCardSkeleton key={index} />;
                  })}
                </div>
              </>
              : (
                getAdvisorList?.length === 0 ? (
                  <NoDataFound message={'MarketGenius Not Found'} />
                ) :
                  <div className="tips-group" data-aos="fade-up">
                    {getAdvisorList?.map((advisors: any) => (
                      <AdvisorsCard advisors={advisors} key={advisors?._id} />
                    ))}
                  </div>
              )
            }

          </div>
        </section>
      </div>
    </>
  );
};

export default AdvisorList;
