import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import SpinnerBtn from '../../../components/common/buttons/SpinnerBtn';
import { profileUpdateThunk } from '../../../store/asyncThunks/Profile';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { decodeToken } from '../../../store/slices/auth/decodedToken';
import { jwtDecode } from 'jwt-decode';

const MyAccount = ({ user }: any) => {
    const dispatch = useAppDispatch();

    const profile = useAppSelector(state => state.profileUpdateReducer)

    const [watchFields, setWatchFields] = useState<any>();

    const profileForm = {
        firstName: "",
        lastName: "",
        mobileNumber: ""
    }

    const profileFormSchema = yup.object().shape({
        firstName: yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters').trim(),
        lastName: yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters').trim(),
        mobileNumber: yup.string().required("Enter mobile number").max(15, 'Mobile number should not exceed 15 characters').trim(),
    });

    const { register, handleSubmit, setValue, getValues, reset, clearErrors, formState: { errors, }, watch } = useForm({
        resolver: yupResolver(profileFormSchema),
        defaultValues: profileForm
    });

    const onSubmit = (values: any) => {
        const data: any = {};
        data['payload'] = values;
        data['updateToken'] = updateToken;
        dispatch(profileUpdateThunk(data));
    };


    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setWatchFields(value)
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (user) {
            const newUser = {
                firstName: user?.firstName,
                lastName: user?.lastName,
                mobileNumber: `${user?.mobileNumber}`
            }
            reset(newUser);
        }
    }, [user])

    const updateToken = (token: any) => {
        localStorage.setItem("auth-user", token);
        const decoded = jwtDecode(token);
        dispatch(decodeToken(decoded));
    }

    return (
        <div className='profile my-2'>
            <div className='card'>
                <h1 className='title'>My Account</h1>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className='inpt-item mb-4'>
                        <input type='text' id='firstName' {...register("firstName")} className={`${errors.firstName?.message ? 'is-invalid ' : ''} form-control ${watchFields?.firstName?.length > 0 ? "valid-field" : ""}`} />
                        <label htmlFor='firstName' className='inpt-label'>First name</label>
                        {errors.firstName?.message ? <span className={`invalid-feedback`} >{errors.firstName?.message}</span> : null}
                    </div>
                    <div className='inpt-item mb-4'>
                        <input type='text' id='lastName' {...register("lastName")} className={`${errors.lastName?.message ? 'is-invalid ' : ''} form-control ${watchFields?.lastName?.length > 0 ? "valid-field" : ""}`} />
                        <label htmlFor='lastName' className='inpt-label'>Last name</label>
                        {errors.lastName?.message ? <span className={`invalid-feedback`} >{errors.lastName?.message}</span> : null}
                    </div>
                    <div className='inpt-item mb-4'>
                        <input type='number' id='mobileNumber' {...register("mobileNumber")} className={`${errors.mobileNumber?.message ? 'is-invalid ' : ''} form-control ${watchFields?.mobileNumber?.length > 0 ? "valid-field" : ""}`} />
                        <label htmlFor='mobileNumber' className='inpt-label'>Mobile number</label>
                        {errors.mobileNumber?.message ? <span className={`invalid-feedback`} >{errors.mobileNumber?.message}</span> : null}
                    </div>
                    <div className="auth-btn">
                        {profile?.loading ?
                            <SpinnerBtn /> :
                            <PrimaryButton btnName="Save" btnType="submit" />
                        }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MyAccount;