import React, { useEffect, useState } from "react";
import ServicesCard from "../../components/common/cards/ServicesCard";
import TipsCard from "../../components/common/cards/TipsCard";
import AdvisorSignUp from "../../components/common/cards/AdvisorSignUp";
import AdvisorsCard from "../../components/common/cards/AdvisorsCard";
import { homeServices } from "../../constants/data/HomeServices";
import { whyFideal } from "../../constants/data/WhyFideal";
import FAQ from "./components/FAQ";
import HomeBanner from "./components/HomeBanner";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { getTopAdvisorThunk } from "../../store/asyncThunks/Advisors";
import { getRecentTipsThunk } from "../../store/asyncThunks/Tips";
import { getFaqThunk } from "../../store/asyncThunks/Faq";
import { useNavigate } from "react-router";
import AdvisorsCardSkeleton from "../../components/common/skeleton/AdvisorsCardSkeleton";
import NoDataFound from "../../components/common/NoDataFound";
import TipsCardSkeletons from "../../components/common/skeleton/TipsCardSkeletons";
import ConfirmationModal from "../../components/common/modal/ConfirmationModal";
import { Helmet } from "react-helmet";
import AOS from "aos";



const Home = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const limit = 9;

  const TopAdvisorList = useSelector((state: any) => state?.topAdvisorReducer?.data?.advisors)
  const RecentTipsList = useSelector((state: any) => state?.RecentTipsReducer?.data?.data)
  const FaqList = useSelector((state: any) => state?.getFaqReducer?.data?.faq)
  const TopAdvisorLoading = useSelector((state: any) => state?.topAdvisorReducer?.loading)
  const RecentTipsLoading = useSelector((state: any) => state?.RecentTipsReducer?.loading)

  const [tokenValue, setTokenValue] = useState('');

  const [listData, setListData] = useState([]);
  const [showmodel, setshowmodel] = useState(false);

  useEffect(() => {
    dispatch(getTopAdvisorThunk(limit));
    dispatch(getRecentTipsThunk(limit));
    dispatch(getFaqThunk());
    const storedValue = localStorage.getItem('auth-user');
    if (storedValue) {
      setTokenValue(storedValue);
    }
  }, []);

  useEffect(() => {
    setListData(RecentTipsList)
  }, [RecentTipsList]);

  const toogleModal = () => {
    setshowmodel(!showmodel)
  }

  const navigateAfterLogin = () => {
    localStorage.clear();
    navigate('/login');
  }
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="wrapper">
      <Helmet>
        <title>FiDeal</title>
      </Helmet>
      <div className="">
        <HomeBanner />
      </div>
      <section className="large-cap">
        <div className="container">
          <h2 className="text-center" data-aos="fade-up">
            Genius shares their <span className="gradient-txt">real experiences</span>
          </h2>
          <div className="sers-group" data-aos="fade-up">
            {homeServices?.map((item, index) => {
              return (
                <>
                  <ServicesCard item={item} key={index} />
                </>
              );
            })}
          </div>
        </div>
      </section>
      <section className="medium-cap gray-bg">
        <div className="container">
          <div className="btn-grp">
            <div data-aos="fade-right">
              <h2 className="">
                Recent <span className="gradient-txt">GeniusTalk</span>
              </h2>
            </div>
            {listData && listData?.length === 0 ? (
              ''
            ) : (
              <div data-aos="fade-left">
                {tokenValue ? (
                  <PrimaryButton btnName="View All"
                    onClick={() => {
                      navigate("/geniustalk");
                    }}
                  />
                ) : (
                  <PrimaryButton btnName="View All"
                    onClick={() => {
                      setshowmodel(true);
                    }}
                  />
                )}
              </div>
            )
            }

          </div>
          {!RecentTipsLoading === false ? (
            <div className="tips-group" data-aos="fade-up">
              {[...Array(9)].map((index) => {
                return <TipsCardSkeletons key={index} />;
              })}
            </div>
          ) : (
            <>
              {listData && listData?.length === 0 ? (
                <NoDataFound message={'No Recent GeniusTalk'} />
              ) : (
                <div className="tips-group" data-aos="fade-up">
                  {listData?.map((tips: any, index: any) => (
                    <TipsCard tips={tips} key={tips._id} listData={listData} setListData={setListData} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </section>
      <section className="large-cap">
        <div className="container" data-aos="slide-up">
          <AdvisorSignUp />
        </div>
      </section>
      <section className="why-fideal">
        <div className="large-cap gradient-bg">
          <div className="container">
            <h2 className="text-center text-white" data-aos="fade-up">Why FiDeal ? </h2>
            <div className="sers-group" data-aos="fade-down">
              {whyFideal?.map((item, index) => {
                return (
                  <>
                    <ServicesCard item={item} key={index} />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="large-cap gray-bg">
        <div className="container">
          <div className="btn-grp">
            <div data-aos="fade-right">
              <h2 className="">
                Top <span className="gradient-txt">MarketGenius</span>
              </h2>
            </div>
            {TopAdvisorList && TopAdvisorList?.length === 0 ? (
              ''
            ) : (
              <div data-aos="fade-left">
                {tokenValue ? (
                  <PrimaryButton btnName="View All"
                    onClick={() => {
                      navigate("/marketgenius");
                    }}
                  />
                ) : (
                  <PrimaryButton btnName="View All"
                    onClick={() => {
                      setshowmodel(true);
                    }}
                  />

                )}

              </div>
            )}

          </div>

          <div className={TopAdvisorList?.length === 0 && !TopAdvisorLoading ? "" : "tips-group"} data-aos="fade-up">
            {!TopAdvisorLoading === false ? (
              [...Array(9)].map((index) => {
                return <AdvisorsCardSkeleton key={index} />;
              })
            ) : (
              <>
                {TopAdvisorList?.length === 0 ? (
                  <NoDataFound message={'No Top MarketGenius'} />
                ) : (
                  <>
                    {TopAdvisorList?.map((advisors: any, key: any) => (
                      <AdvisorsCard advisors={advisors} key={advisors._id} />
                    ))}
                  </>
                )}
              </>
            )}

          </div>


        </div>
      </section>
      <section className="large-cap">
        <div className="container">
          <h2 className="text-center" data-aos="fade-up">
            Frequently Asked <span className="gradient-txt">Questions</span>
          </h2>
          <div className="tips-group faq">
            <FAQ faq={FaqList} />
          </div>
        </div>
      </section>
      <ConfirmationModal
        showModal={showmodel}
        toggle={toogleModal}
        modalTitle={'Login Required!!!'}
        description={'Kindly log in to access the exclusive privileges.'}
        primaryBtn={'Sign In'}
        primaryAction={navigateAfterLogin}
      />
    </div>
  );
};

export default Home;
