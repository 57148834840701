import { createSlice } from "@reduxjs/toolkit";
import { getRecentTipsThunk } from "../../asyncThunks/Tips";
import { topadvisorState } from "../../interfaces/topadvisor";


const initialState: topadvisorState = {
    error: null,
    data: {},
    loading: false,
}

export const getallRecentTips = createSlice({
    name: "getRecentTips",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getRecentTipsThunk.pending, (state, action) => {
            state.error = null;
            // state.data = null;
            state.loading = true;
        });
        builder.addCase(getRecentTipsThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getRecentTipsThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },

})


export default getallRecentTips.reducer;