import React from "react";
import { Link } from "react-router-dom";
import appLogo from "../../../assets/images/logo.svg";

const Logo = () => {
  return (
    <div>
      <Link to={"/"}>
        <img src={appLogo} alt="appLogo" />
      </Link>
    </div>
  );
};

export default Logo;
