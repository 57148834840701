import React from 'react';

const OutlineButton = ({ btnName, onClick, btnType }: any) => {
    return (
        <div>
            <button
                className="outline btn-common"
                type={btnType}
                onClick={onClick}
            >
                <span> {btnName}</span>
            </button>
        </div>
    );
};

export default OutlineButton;