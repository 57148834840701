import { createAsyncThunk } from "@reduxjs/toolkit";
import { contactUs } from "../../helpers/backend_helper";
import toast from "react-hot-toast";

export const contactUsThunk = createAsyncThunk(
    "contactUsEnquiry/contactUsThunk",
    async (props: any) => {
        const { data, enquirySuccess } = props
        try {
            const response = await contactUs(data);
            if (response?.status?.toLowerCase() === "success") {
                enquirySuccess(true);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error) {
            toast.error("An error occurred while adding the Enquiry.")
        }
    }
);

function enquirySuccess(arg0: boolean) {
    throw new Error("Function not implemented.");
}
