import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import { useAppDispatch } from "../../store/store";
import { forgotPswThunk, resetPswThunk } from "../../store/asyncThunks/auth";
import { useNavigate, useParams } from "react-router";
import Applogo from '../../assets/images/white-logo.svg';
import { NavLink } from "react-router-dom";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import loginImage from '../../assets/images/login-image.png';
import pswShow from '../../assets/images/svgicons/eye.svg';
import pswHide from '../../assets/images/svgicons/eye-off.svg';
import { useSelector } from "react-redux";
import SpinnerBtn from "../../components/common/buttons/SpinnerBtn";
import PasswordStrengthBar from 'react-password-strength-bar';
import homeIcon from "../../assets/images/svgicons/home.svg";

interface FormData {
    password: string;
    confPwd: string;
    verificationToken: string;
    email: string;
}

const ResetPassword = () => {

    document.title = "Reset Password | FiDeal";

    const decodedEmail: any = localStorage.getItem("reg-site");
    const userEmail = atob(decodedEmail);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confPwdShow, setConfPwdShow] = useState(false);
    const [watchFields, setWatchFields] = useState<any>();
    const [getpassword, setGetPassword] = useState('');

    const handleChange = (event: any, field: any) => {
        setGetPassword(event.target.value);
        if (event.target?.value) {
            clearErrors('password')
        } else {
            setError(field, { type: "custom", message: 'Enter password' });
        }
    };

    const loading = useSelector((state: any) => state?.resetPasswordReducer?.loading)

    const resetpwsForm = {
        email: userEmail,
        password: '',
        confPwd: '',
        verificationToken: ''
    };
    const resetpwsFormSchema = Yup.object({
        password: Yup.string().required('Enter password').min(8, 'Password must be at least 8 characters long'),
        confPwd: Yup.string().oneOf([Yup.ref('password'), ''], 'Passwords must match').required('Enter confirm password'),
        verificationToken: Yup.string().required("Enter verification code"),
    });

    const { register, handleSubmit, formState: { errors, }, watch, clearErrors, setError } = useForm({
        defaultValues: resetpwsForm,
        resolver: yupResolver(resetpwsFormSchema),
    });

    const navigateAfterSignup = () => {
        navigate("/login");
    };

    const onSubmit = (values: any) => {
        const data: any = {};
        data["values"] = values;
        data["navigate"] = navigateAfterSignup;
        dispatch(resetPswThunk(data));
    };
    // const handleWheel = (event: any) => {
    //     event.preventDefault(); // Prevent the default scrolling behavior
    // };
    useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            setWatchFields(value)
        )
        return () => subscription.unsubscribe()
    }, [watch]);

    // useEffect(() => {
    //     setPassword(getpassword)
    // }, [getpassword]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const resendOTP = () => {
        const data: any = {};
        data["values"] = { email: userEmail };
        data["navigate"] = '';
        setMinutes(1);
        setSeconds(30);
        dispatch(forgotPswThunk(data))
    };

    return (
        <div className="auth-screen">
            <div className="auth-overlayshape"></div>
            <div className="app-logo">
                <NavLink to={'/'}> <img src={Applogo} alt="Applogo" /></NavLink>
            </div>
            <NavLink to={'/'}>
                <div className="backto-home" >
                    <img src={homeIcon} alt="homeIcon" />
                </div>
            </NavLink>
            <div className="row">
                <div className="col-lg-5">
                    <div className="cont-bg">
                        <div className="ripple-small"></div>
                        <figure>
                            <img src={loginImage} alt="login-img" />
                        </figure>
                        <div className="ripple-small right"></div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="auth-form">
                        <div className="welcome-cont">
                            <h2>Reset Password</h2>
                            <p>Enter the 6 digits code that you received on your email and set the new password for your account.</p>

                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                            <div className='inpt-item mb-4'>
                                <input type='number' id='verificationToken' {...register("verificationToken")} className={`${errors.verificationToken?.message ? 'is-invalid ' : ''} form-control ${watchFields?.verificationToken?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='verificationToken' className='inpt-label'>Verification code<span>*</span></label>
                                {errors.verificationToken?.message ? <span className={`invalid-feedback`} >{errors.verificationToken?.message}</span> : null}
                                <div className="countdown-text">
                                    {seconds > 0 || minutes > 0 ? (
                                        <p className="f-size-12 timing">Time remaining: <span className="remaining-time">{minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}</span>
                                        </p>
                                    ) : (
                                        <p className="f-size-12 timing">Didn't receive code?</p>
                                    )}

                                    <button className="border-0 bg-transparent p-0 resend-code"
                                        disabled={seconds > 0 || minutes > 0}
                                        style={{
                                            color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#DF0C00",
                                        }}
                                        onClick={resendOTP}
                                    >
                                        Resend code
                                    </button>
                                </div>
                            </div>
                            <div className='inpt-item mb-4'>
                                <input type={passwordShow ? "text" : "password"} id='password' {...register("password")} onChange={(e) => handleChange(e, "password")} className={`${errors.password?.message ? 'is-invalid ' : ''} form-control ${watchFields?.password?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='password' className='inpt-label'>New password<span>*</span></label>
                                <span>  <button onClick={() => setPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                                    {passwordShow ? <img src={pswHide} alt="password" /> : <img src={pswShow} alt="password" />}
                                </button></span>
                                <div className="w-100">
                                    {getpassword.length > 0 && (
                                        <PasswordStrengthBar password={getpassword}
                                            scoreWords={['Very weak', 'weak', 'Medium', 'Good', 'Strong']}
                                            barColors={['#D7D7D7', '#EA1111', '#FFAD00', '#9bc158', '#00b500']}
                                        />
                                    )}
                                </div>
                                {errors.password?.message ? <span className={`invalid-feedback`} >{errors.password?.message}</span> : null}
                            </div>
                            <div className='inpt-item mb-4'>
                                <input type={confPwdShow ? "text" : "password"} id='password' {...register("confPwd")} className={`${errors.confPwd?.message ? 'is-invalid ' : ''} form-control ${watchFields?.confPwd?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='password' className='inpt-label'>Confirm password<span>*</span></label>
                                {errors.confPwd?.message ? <span className={`invalid-feedback`} >{errors.confPwd?.message}</span> : null}
                                <span>  <button onClick={() => setConfPwdShow(prevState => !prevState)} type="button" id="password-addon">
                                    {confPwdShow ? <img src={pswHide} alt="password" /> : <img src={pswShow} alt="password" />}
                                </button></span>
                            </div>
                            <div className="auth-btn">
                                {loading ? <SpinnerBtn /> :
                                    <PrimaryButton btnName="Proceed" btnType="submit" />}
                            </div>
                        </form>
                        <div className="back-to">
                            <p>Go back to <NavLink to={'/login'}>Sign In</NavLink></p>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    );
};

export default ResetPassword;
