import React from "react";

const PrimaryButton = ({ btnName, onClick, btnType }: any) => {
  return (
    <>
      <div>
        <button
          className="primary-btn btn-common"
          type={btnType}
          onClick={onClick}
        >
          {btnName}
        </button>
      </div>
    </>
  );
};

export default PrimaryButton;
