import { useEffect } from "react";
import { gsap } from 'gsap';

interface props {
  title: string;
  description: string;
  imagePath: string;
}

const Banner = (props: props) => {
  useEffect(() => {
    // GSAP Animation
    const tl = gsap.timeline();
    tl.from('.text-container', {
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.3,
    });

  }, []);
  const { title, description, imagePath } = props;
  return (
    <div className="common-banner">
      <div className="banner-bg">
        <div className="container">
          <div className="text-container">
            <div className="col-lg-4">
              <div className="image-wrapper">
                <div className="image-overlay"></div>
                <div className="breathing-circle">
                  <img src={imagePath} alt="banner-image" />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="text-wrapper">
                <h1 className="title">{title}</h1>
                <p className="description">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
