import ImageBlog from "../../../components/common/ImageBlog"
import serviceImage from "../../../assets/images/svgimage/faq.svg";
import aboutus from "../../../assets/images/about_our_company.png"
import rippleIconPath from "../../../assets/images/svgicons/building.svg";
import { ourCompany } from "../../../constants/data/AboutCompany";
import AOS from "aos";
import { useEffect } from "react";


const AboutCompany = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <>
            <div className="about-us">
                <div className="row">
                    <div className="col-md-6" data-aos="fade-right">
                        <h2><span className="text-Gradient">About</span> Our Company</h2>
                        <p>FiDeal stands out as a revolutionary platform, which connects the professional traders who are capable of analyzing the market and the normal market participants. Our cost-effective, comprehensive, and referral-driven solution redefines the marketgenius service landscape. Traders and Investors enjoy the flexibility to select their preferred MarketGenius, unlocking access to valuable ideas and informations shared by them. This not only enhances their trading experience but also creates opportunities for additional revenue, with a share of proceeds going to the specific understanding of their views.</p>
                        <p>In essence, FiDeal stands as a unique and invaluable tool for both stock traders and investors, granting them the ability to:</p>
                        <ul className="list-container">
                            {ourCompany && ourCompany.map((item, index) => (
                                <li className="list-item" key={index}>
                                    <div className="para">
                                        <p>{item.para}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-md-6" data-aos="fade-left">
                        <div className="ripple-content">
                            <ImageBlog
                                imagePath={aboutus}
                                layout={1}
                                altName={"ripple-Image"}
                                rippleIcon={rippleIconPath}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AboutCompany