import { createSlice } from "@reduxjs/toolkit";
import { advisorState } from "../../interfaces/advisor";
import { reviewRatingThunk } from "../../asyncThunks/Rating";


const initialState: advisorState = {
    error: null,
    data: [],
    loading: false,
}

export const ReviewRating = createSlice({
    name: "reviewRating",
    initialState,
    reducers: {
        resetReviewRating: (state) => {                        
            state.data = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(reviewRatingThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(reviewRatingThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;

            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(reviewRatingThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },

})

export const { resetReviewRating } = ReviewRating.actions;

export default ReviewRating.reducer;