
import Banner from '../../components/common/banners/Banner'
import service from "../../assets/images/services-banner.png";
import ServiceTypes from '../../components/common/ServiceTypes';
import waterDrop from "../../assets/images/svgicons/waterDrop.svg";
import moneyBill from "../../assets/images/svgicons/moneyBill.svg";
import chartPie from "../../assets/images/svgicons/chartpie.svg";
import { CurrencyItems } from "../../constants/data/CurrencyItems";
import { CommodityItems } from '../../constants/data/CommodityItems';
import { EquityItems } from '../../constants/data/EquityItems';
import currencyImage from "../../assets/images/currency.png";
import commodityImage from "../../assets/images/commodity.png";
import equityImage from "../../assets/images/equity.png";
import { Helmet } from 'react-helmet';

const Services = () => {
    return (
        <>
            <Helmet>
                <title>KnowledgeHub | FiDeal</title>
            </Helmet>
            <Banner
                title="KnowledgeHub"
                description="The knowledge hub provides insights and perspectives from multiple individuals with extensive practical experience in trading equity, commodity, and currency markets. They willingly share their wealth of knowledge with us."
                imagePath={service}
            />
            <div className='service-page'>
                <section className='large-cap'>
                    <div className='container'>
                        <ServiceTypes ServicesData={EquityItems} rippleIconPath={chartPie} rippleImage={equityImage} layoutStyle={3} />

                    </div>
                </section>
                <section className='medium-cap gray-bg commodity-content'>
                    <div className='container'>
                        <ServiceTypes ServicesData={CommodityItems} customClass={'flex-row-reverse'} rippleIconPath={waterDrop} rippleImage={commodityImage} layoutStyle={2} />
                    </div>
                </section>
                <section className='large-cap currencyItems'>
                    <div className='container'>
                        <ServiceTypes ServicesData={CurrencyItems} rippleIconPath={moneyBill} layoutStyle={1} rippleImage={currencyImage} />

                    </div>
                </section>
            </div>
        </>
    )
}

export default Services