import { useEffect } from "react";
import { rolesData } from "../../../constants/data/FidealRoles";
import AOS from "aos";

const AboutRoles = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <>
            <div className="card-bg">
                <div className="gradient-bg">
                    <div className="container">
                        <div className="text-center">
                            <h1 data-aos="fade-up">Fideal's <span>Roles ?</span></h1>
                            <p data-aos="fade-up">FiDeal is a company dedicated to assisting clients in their financial market endeavours
                                by offering them valuable and well-considered ideas. What sets FiDeal apart from other marketgenius services are the following key attributes</p>
                        </div>
                        <div className="row" data-aos="fade-up">
                            {rolesData.map((role, index) => (
                                <div className="col-md-3" key={index}>
                                    <div className="roles-card">
                                        <div className="text-center">
                                            <div className="img-bg-one">
                                                <img src={role.image} alt={`Frame-${index + 1}-img`} />
                                            </div>
                                            <h6>{role.title}</h6>
                                            <p>{role.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutRoles