import whiteStar from "../../../assets/images/svgicons/whiteStar.svg";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import SpinnerBtn from "../../../components/common/buttons/SpinnerBtn";

const ReviewCard = ({ reviewRating, loadMore, reviewTotalCount, loader }: any) => {

  const isLoadMoreDisabled = reviewTotalCount == reviewRating?.length;

  const handleLoadMore = () => {
    loadMore();
  };


  return (
    <>
      <div className="review-item">
        {reviewRating?.map((item: any) => {

          return (
            <div className="user-review">
              <div className={`item-header`}>
                <div className="user">
                  <div className={`star star-${item?.rating}`}>
                    {item?.rating} <img src={whiteStar} alt="star" />
                  </div>
                  <div className="title">{item?.userId?.firstName && item?.userId?.lastName ? item?.userId?.firstName + " " + item?.userId?.lastName : ""}</div>
                </div>
                <div className="date-time">
                  <div className="date">{item?.updatedAt
                    ? new Date(item.updatedAt).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                    : ''}</div>
                  <div className="time">{item?.updatedAt ? new Date(item.updatedAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}</div>
                </div>
              </div>
              <div className="item-body">
                {item?.review || ""}
              </div>
            </div>
          );
        })}
      </div>
      {!isLoadMoreDisabled &&
        <div className="load-more-button mt-5">
          {
            loader ? <SpinnerBtn /> :
              <PrimaryButton btnName="Load More" btnType='button' onClick={() => handleLoadMore()} />
          }
        </div>
      }
    </>
  );
};

export default ReviewCard;
