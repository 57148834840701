import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface stars {
  stars?: any;
  ratingCount?: any;
  reviewCount?: string | number;
}

const RatingProgressSkeleton = (props: stars) => {
  return (
    <div className="rating col-md col-lg-4">
      {[...Array(5)].map((star) => (
        <div className={`star-list star-${star}`} key={star}>
          <div className="count-star">
            <div className="star-image">
              <Skeleton height={'16px'} width={'16px'} />
            </div>
          </div>
          <div className="progress">
            <Skeleton height={'10px'} width={'100%'} />
          </div>
        </div>
      ))}
      <div className="overall-rating">
        <div className="rating-count">
          <div className="overall-count">
            <h1>
              <Skeleton height={'20px'} width={'40px'} />
            </h1>
            <div className="star-image">
              <Skeleton height={'16px'} width={'16px'} />
            </div>
          </div>
          <p>
            <Skeleton height={'12px'} width={'80px'} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default RatingProgressSkeleton;
