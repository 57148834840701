import { createSlice } from "@reduxjs/toolkit";
import { signupRequestInterface } from "../../interfaces/signupRequest";


const initialState: signupRequestInterface = {
    data: null
}

export const signupRequest = createSlice({
    name: "signupRequest",
    initialState,
    reducers: {
        resetSignupRequest: (state => {
            state.data = null;
        }),
        addGeneralDetails: ((state, action) => {
            const { payload } = action;
            state.data = payload;
        }),
    }
})

export const { resetSignupRequest, addGeneralDetails } = signupRequest.actions;

export default signupRequest.reducer;