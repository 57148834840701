import currency from "../../assets/images/svgicons/currency.svg";
import commodity from "../../assets/images/svgicons/commodity.svg";
import equity from "../../assets/images/svgicons/equity.svg";

export const homeServices = [
  {
    icon: equity,
    title: "Equity",
    description:
      "Access real-time insights from traders for Day Trading, Swing Trading, Positional Trading, and Long-term Investments, along with specific Option Calls.",
  },
  {
    icon: commodity,
    title: "Commodity",
    description:
      "Receive geniustalk on Bullion, Base Metals, and Energy, as well as valuable insights into MCX Options.",
  },
  {
    icon: currency,
    title: "Currency",
    description:
      "Unlock trading potential with geniustalk on USD/INR, EUR/INR, JPY/INR, GBP/INR, EUR/USD, GBP/USD, and USD/JPY.",
  },
];
