import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ReviewCardSkeleton = () => {
  return (
    <>
      <div className="review-item">
        {[...Array(5)].map((index) => (
          <div className="user-review" key={index}>
            <div className={`item-header`}>
              <div className="user">
                <div className={` star-${index}`}>
                  <Skeleton height={"25px"} width={'55px'} borderRadius={'50px'} />
                </div>
                <div className="title">
                  <Skeleton height={'16px'} width={'80px'} />
                </div>
              </div>
              <div className="date-time">
                <div className="date">
                  <Skeleton height={'12px'} width={'60px'} />
                </div>
                <div className="time">
                  <Skeleton height={'12px'} width={'60px'} />
                </div>
              </div>
            </div>
            <div className="item-body">
              <Skeleton count={3} height={'16px'} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ReviewCardSkeleton;
