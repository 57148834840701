import Banner from "../../components/common/banners/Banner";
import contactBanner from "../../assets/images/contact-ban.png";
import { Link } from "react-router-dom";
import ContactInfo from "./components/ContactInfo";
import ContactForm from "./components/ContactForm";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | FiDeal</title>
      </Helmet>
      <div className="contact-us">
        <Banner
          title="Contact Us"
          description="We're dedicated to supporting you in attaining your financial aspirations. While the concept is ours, the triumph lies in your hands. Accessing multiple perspectives and insights from various individuals via a unified portal, you'll gain a comprehensive understanding of real-time market updates. Stay engaged with us to stay informed."
          imagePath={contactBanner}
        />

        <section className="large-cap contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 contact-info">
                <ContactInfo />
              </div>
              <div className="col-lg-8 contact-form">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
