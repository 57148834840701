import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Login Method
export const login = (data: any) => post(url.LOGIN, data);
export const signup = (data: any) => post(url.SIGNUP, data);
export const emailVerification = (data: any) => post(url.EMAIL_VERIFIY, data);
export const emailVerificationResent = (data: any) => post(url.EMAIL_VERIFIY_RESENT, data);
export const forgotPassword = (data: any) => post(url.FORGOT_PASSWORD, data);
export const forgotPasswordReset = (data: any) => post(url.FORGOT_PASSWORD_RESET, data);
// Advisors
export const getadvisor = (data: any) => post(url.GET_ADVISOR, data)
//Top Advisor
export const getTopAdvisor = (limit: number) => { return get(`${url.GET_TOP_ADVISOR}/${limit}`); };
//Get Tips
export const getTips = (data: any, currentPage: number, dataPerPage: number) => post(`${url.GET_TIPS}/${currentPage}/${dataPerPage}`, data)
//Recent Tips
export const getRecentTips = (limit: number) => { return get(`${url.GET_RECENT_TIPS}/${limit}`); };
//Get  By Advisor Id
export const getAdvisorById = (id: number) => { return get(`${url.GET_ADVISOR_BY_ID}/${id}`) };
//Get Tips by Id
export const getTipsByAdvisor = (id: number) => { return get(`${url.GET_TIPS_BY_ADVISOR}/${id}`); };
//Add Rating
export const addRating = (data: any) => post(url.ADD_RATING, data)
//get Faq
export const getFaq = () => get(url.GET_FAQ);
//get Category
export const getCategory = () => get(url.GET_CATEGORY)
//get Tips By Id Filter
export const getTipByAdvisorFilter = (data: any) => post(url.GET_TIPS_BY_ADVISOR_FILTER, data)
//pagination for reviews
export const reviewRating = (currentPage: any, pageSize: any, advisorId: any) => post(`${url.REVIEW_RATING}/${currentPage}/${pageSize}`, advisorId);
//contact us
export const contactUs = (data: any) => post(url.CONTACT_US, data)
//Marketo Metre
export const getMarkettoMeter = () => get(url.GET_MARKETTO_METER)
//like dislike
export const updateLikesAndDislikeStatus = (data: any) => post(`${url.UPDATE_LIKES}`, data);
//add signup request
export const addSignupRequest = (data: any, config: any) => post(`${url.ADD_SIGNUP_REQUEST}`, data, config);
//profile
export const updateProfile = (data: any) => post(`${url.UPDATE_PROFILE}`, data);
export const updateProfilePassword = (data: any) => post(`${url.UPDATE_PASSWORD}`, data);
export const updateProfileImage = (data: any, headers: any) => post(`${url.UPDATE_PROFILE_IMAGE}`, data, headers);
export const getProfileImage = (data: any) => post(`${url.GET_PROFILE_IMAGE}`, data);
//Settings
export const getSettingsData = () => get(`${url.GET_Settings}`);
