import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { decodeToken } from '../../../store/slices/auth/decodedToken';
import profileicon from '../../../assets/images/svgicons/profile.svg';
import logOut from '../../../assets/images/svgicons/logout.svg';
import downArrow from '../../../assets/images/svgicons/down-arrow.svg';

const HeaderProfile = ({ userData }: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const imagePath = useAppSelector(state => state.getProfileImgReducer?.data?.file)

    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const logOutApp = () => {
        localStorage.clear();
        navigate('/');
        setMenuOpen(false)
        dispatch(decodeToken(null));
    }

    return (
        <div className='position-relative'>
            <div className='header-profile' onClick={handleMenuToggle}>
                <div className='pro-img'>
                    {imagePath ?
                        <img src={imagePath} alt='profile' /> :
                        <span>{userData?.firstName?.charAt(0)?.toUpperCase()}</span>
                    }
                </div>
                <h5 className='user-name'>{userData?.firstName}
                    <span><img className='down-arrow' src={downArrow} alt='downarrow' /></span>
                </h5>
            </div>
            {isMenuOpen && (
                <div className="profile-menu-dropdown" onBlur={handleMenuClose} tabIndex={0}>

                    <ul>
                        <li><NavLink to={'/my-profile'}><span><img src={profileicon} alt='profileicon' /></span>My Profile</NavLink></li>
                        <li><NavLink to={'/'} onClick={logOutApp}><span><img src={logOut} alt='logOut' /></span> Logout</NavLink></li>

                    </ul>
                </div>
            )}
        </div>
    );
};

export default HeaderProfile;