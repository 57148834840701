import searchIcon from "../../assets/images/svgicons/search-icon.svg";

interface SearchInputProps {
  onSearchChange: (value: string) => void;
  className?: string;
}
const SearchInput = (props: SearchInputProps) => {
  const classname = "custom-input " + props?.className;
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onSearchChange(event.target.value);
  };

  return (
    <div className="search-input">
      <input
        {...props}
        type="text"
        className={classname}
        placeholder={`Search`}
        onChange={handleInputChange}
      />
      <img className="search-icon" src={searchIcon} alt="search-icon" />
    </div>
  );
};

export default SearchInput;
