import Registered from "../../assets/images/svgicons/sebi_registered_advisors.svg";
import riskManage from "../../assets/images/svgicons/risk_managed_calls.svg";
import Special from "../../assets/images/svgicons/award_winning_advisors.svg";
import Accuracy from "../../assets/images/svgicons/industries_best_advisors.svg";

export const whyFideal = [
  {
    icon: Registered,
    description: "Actual geniuses sharing their expertise",
  },
  {
    icon: Accuracy,
    description: "Fostering idea exchange among us",
  },
  {
    icon: riskManage,
    description: "Motivated community from within",
  },
  {
    icon: Special,
    description: "Practical experience speaks louder than theory",
  },
];
