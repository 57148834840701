import React, { useEffect, useState } from "react";
import arrowDown from "../../assets/images/svgicons/arrow-down.svg";
import activeImage from "../../assets/images/svgicons/circle-tick.svg";
interface props {
  menuList?: Array<any>;
  categories: any;
  setValues?: any;
  values?: any;
}

const AccordionMenu = (props: props) => {
  const { categories, setValues, values } = props;

  const [activeIndex, setActiveIndex] = useState<String>("");
  const [active, setActive] = useState<Array<String>>([]);
  const [indexCount, setIndexCount] = useState<any>({});

  const handleAccordionClick = (id: any) => {
    setActiveIndex(id === activeIndex ? "" : id);
  };

  const handleInnerItemClick = async (item: any) => {
    const index = active.indexOf(item?._id);
    const newActive = [...active];
    if (index !== -1) {
      await newActive.splice(index, 1);
      if (indexCount[item?.category]) {
        if (indexCount[item?.category] > 0) {
          const newCount = { [`${item?.category}`]: indexCount[item?.category] - 1 };
          setIndexCount({ ...indexCount, ...newCount });
        }
      }
    } else {
      await newActive.push(item?._id);
      const previousCount = indexCount[item?.category] ? indexCount[item?.category] : 0;
      const newCount = { [`${item?.category}`]: previousCount + 1 };
      setIndexCount({ ...indexCount, ...newCount });
    }
    setActive(newActive);
    const newValues = {
      ...values,
      subService: newActive?.length > 0 ? newActive : null,
    };
    setValues(newValues);
  };

  useEffect(() => {
    if (values?.subService?.length > 0) {
      const newActiveList = [...values?.subService];
      setActive(newActiveList);
    }
  }, []);

  return (
    <div className="accordion-menu">
      {categories?.map((item: any, index: any) => {
        let count = 0;
        if (indexCount[item?._id]) {
          count = indexCount[item?._id]
        }
        return < div
          key={item?._id
          }
          className={`accordion-item ${activeIndex === item?._id ? "open-item" : ""
            }`}
        >
          <div
            className="accordion-header"
            onClick={() => {
              handleAccordionClick(item?._id);
            }}
          >
            <h5>{item?.label?.toLowerCase()}</h5>
            <div className="action">
              {count > 0 && <div className="outline count">
                {count > 0 ? <span>{count}</span> : ""}
              </div>}
              {activeIndex ? (
                <img src={arrowDown} alt="" />
              ) : (
                <img src={arrowDown} alt="" />
              )}
            </div>
          </div>
          {activeIndex === item?._id &&
            item?.subcategory &&
            item?.subcategory?.length > 0 &&
            item?.subcategory?.map((innerItem: any, innerIndex: number) => {
              const id = innerItem?._id;
              return (
                <div
                  key={innerItem?._id}
                  className={`accordion-body ${active?.includes(id) ? "active-item" : ""
                    }`}
                  onClick={() => {
                    handleInnerItemClick(innerItem);
                  }}
                >
                  <div className="content-body">
                    <p> {innerItem?.label}</p>
                  </div>
                  {active?.includes(id) && (
                    <div className="active-border">
                      <img src={activeImage} alt="active" />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      })}
    </div >
  );
};

export default AccordionMenu;
