import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide, } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import BannerImage from "../../../assets/images/svgimage/banner.svg";
import BannerShape from "../../../assets/images/shape.png";
import bannerIcon from "../../../assets/images/svgicons/banner-icon.svg";
import ImageBlog from "../../../components/common/ImageBlog";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { gsap } from 'gsap';
import playIcon from '../../../assets/images/svgicons/playbtn.svg';
import playpush from '../../../assets/images/svgicons/playpush.svg';

const Slider = () => {
  // const swiperRef = useRef(null)
  const navigate = useNavigate();

  const [playButton, setPlayButton] = useState(false);

  const authUser = useSelector((state: any) => state.decodeTokenReducer?.data);
  useEffect(() => {
    // GSAP Animation
    const tl = gsap.timeline();
    tl.from('.slider-bg', {
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.3,
    });
  }, []);

  const handleSignIn = () => {
    navigate('/signup')
  }
  return (
    <div className="slider-bg">
      <div className="h-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          centeredSlides={true}
          effect={'fade'}
          // autoplay={true}
          loop={true}
          onSlideChange={() => gsap.timeline().restart()}
          pagination={{
            clickable: true,
            // el: '.swiper-pagination',
            el: '.home-pagination',
            // renderBullet: (index, className) => {
            //   return '<span class="' + className + '">' + (index + 1) + "</span>";
            // },
          }}
          autoplay={{

            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="home-swiper"
        >
          <SwiperSlide>
            <div className="h-sl-itm">
              <div>
                <h1>Limitless Choices,
                  a multitude of highly trusted marketgenius…
                </h1>
                {/* <span>1000+</span> */}
                <p>
                  Find the perfect marketgenius that matches your expectations, all conveniently available through our centralized portal.
                </p>
                {authUser ? "" :
                  <PrimaryButton btnName={"Join Today "} onClick={handleSignIn} />}
              </div>
              <div className="banner-img">
                {/* <div className="ripple-large"></div> */}
                <ImageBlog
                  imagePath={BannerImage}
                  layout={1}
                  altName={"FAQ-image"}
                  rippleIcon={bannerIcon}
                />

              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-sl-itm">
              <div>
                <h1>
                  Trading Community Hub
                </h1>
                <p>
                  Our platform facilitates the sharing of trading insights, enabling anyone to contribute and access valuable information.
                </p>
                {authUser ? "" :
                  <PrimaryButton btnName={"Join Today"} onClick={handleSignIn} />}
              </div>
              <div className="banner-img">
                <ImageBlog
                  imagePath={BannerImage}
                  layout={1}
                  altName={"FAQ-image"}
                  rippleIcon={bannerIcon}
                />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="">
        {/* <div onClick={() => { setPlayButton(!playButton) }}>
          <img src={!playButton ? playIcon : playpush} alt="playicon" />
        </div> */}
        <div className="home-pagination"></div>
      </div>
    </div>
  );
};

export default Slider;
