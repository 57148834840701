import { createSlice } from "@reduxjs/toolkit";
import { resetpswState } from "../../interfaces/auth";
import { resetPswThunk } from "../../asyncThunks/auth";

const initialState: resetpswState = {
  error: null,
  data: null,
  loading: false,
};

export const resetPassword = createSlice({
  name: "forgotpsw",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPswThunk.pending, (state, action) => {
      state.error = null;
      state.data = null;
      state.loading = true;
    });
    builder.addCase(resetPswThunk.fulfilled, (state, action) => {
      state.error = null;
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(resetPswThunk.rejected, (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {  } = login.actions;

export default resetPassword.reducer;
