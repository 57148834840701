import React, { useEffect } from "react";
import TipsCard from "../../../components/common/cards/TipsCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { getRecentTipsThunk } from "../../../store/asyncThunks/Tips";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../../../components/common/NoDataFound";
import { gsap } from 'gsap';

const LatestTips = () => {

  const RecentTipsList = useSelector((state: any) => state.RecentTipsReducer?.data?.data)
  useEffect(() => {
    // GSAP Animation
    const tl = gsap.timeline();
    tl.from('.latest-tips', {
      opacity: 0,
      y: 50,
      duration: 1.1,
      stagger: 0.5,
    });
  }, []);
  return (
    <div className="latest-tips">
      <div className="latest-pagination">
        <div className="lt-head">
          <p>Live</p>
          <h6>GeniusTalk</h6>
        </div>
        <div className="swiper-pagination-latest"></div>
      </div>
      {!RecentTipsList || RecentTipsList?.length === 0 ? (
        <div className="latest-nodata">
          <NoDataFound message={'No live geniustalk'} />
        </div>
      ) : (
        <>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            centeredSlides={true}
            // loop={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              el: '.swiper-pagination-latest',
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="TipsSwiper"
          >

            {RecentTipsList?.slice(0, 3)?.map((tips: any, index: any) => (
              <>
                <SwiperSlide key={index}>
                  <TipsCard tips={tips} index={tips._id} hideLikes={true} hideUpdateBatchImage={true} className='live' />
                </SwiperSlide>
              </>
            ))}
          </Swiper>

        </>
      )}

    </div>
  );
};

export default LatestTips;
