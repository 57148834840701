import { createSlice } from "@reduxjs/toolkit";
import { getTopAdvisorThunk } from "../../asyncThunks/Advisors";
import { topadvisorState } from "../../interfaces/topadvisor";


const initialState: topadvisorState = {
    error: null,
    data: {},
    loading: false,
}

export const getTopAdvisor = createSlice({
    name: "getTopadvisor",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getTopAdvisorThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(getTopAdvisorThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getTopAdvisorThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },

})


export default getTopAdvisor.reducer;