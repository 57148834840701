import React from "react";

const SecondaryButton = ({ btnName, onClick, btnType }: any) => {
  return (
    <div>
      <button
        className="secondary-btn btn-common"
        type={btnType}
        onClick={onClick}
      >
        {btnName}
      </button>
    </div>
  );
};

export default SecondaryButton;
