import { createSlice } from "@reduxjs/toolkit";
import { advisorState } from "../../interfaces/advisor";
import { getProfileImgThunk } from "../../asyncThunks/Profile";

const initialState: advisorState = {
    error: null,
    data: null,
    loading: false,
}

export const getProfileImg = createSlice({
    name: "getProfileImg",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProfileImgThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(getProfileImgThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload?.data;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getProfileImgThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },

})

export default getProfileImg.reducer;