import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTipByAdvisorFilter, getadvisor, getAdvisorById, getTopAdvisor } from "../../helpers/backend_helper";


export const getadvisorThunk = createAsyncThunk(
    "getadvisor/getadvisorThunk",
    async (data: any) => {
        const response = await getadvisor(data);
        return await response;
    }
);

export const getAdvisorByIdThunk = createAsyncThunk(
    "getAdvisorById/getAdvisorByIdThunk",
    async (id: any) => {
        const response = await getAdvisorById(id);
        return await response;
    }
);

export const getTopAdvisorThunk = createAsyncThunk(
    "getadvisor/getTopAdvisorThunk",
    async (limit: number) => {
        const response = await getTopAdvisor(limit);
        return await response;
    }
);

export const getTipByAdvisorFilterThunk = createAsyncThunk(
    " getTipsByFilter/getTipByAdvisorFilter",
    async ({ payload }: { payload: string }) => {
        const data = { guide: payload };
        const response = await getTipByAdvisorFilter(data);
        return await response;
    }
)