import { createSlice } from "@reduxjs/toolkit";
import { getMarkettoMeterThunk } from "../../asyncThunks/Marketto";
import { topadvisorState } from "../../interfaces/topadvisor";


const initialState: topadvisorState = {
    error: null,
    data: {},
    loading: false,
}

export const getMarketto = createSlice({
    name: "GetMarkettoMater",
    initialState,
    reducers: {
        updateMarkettoData: ((state, action) => {
            state.data = action.payload;
        })
    },
    extraReducers: (builder) => {
        builder.addCase(getMarkettoMeterThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(getMarkettoMeterThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getMarkettoMeterThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },

})

export const { updateMarkettoData } = getMarketto.actions;

export default getMarketto.reducer;