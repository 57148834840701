import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../layout/Layout";
import Home from "../pages/home/Home";
import About from "../pages/about_us/About";
import AdvisorList from "../pages/advisors/AdvisorList";
import ViewAdvisor from "../pages/advisors/ViewAdvisor";
import Services from "../pages/services/Services";
import Tips from "../pages/tips/Tips";
import ContactUs from "../pages/contact_us/ContactUs";
import Login from "../pages/authentication/Login";
import AdvisorLogin from "../pages/authentication/advisors/AdvisorsLogin";
import Signup from "../pages/authentication/Signup";
import EmailVerification from "../pages/authentication/EmailVerification";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import ResetPassword from "../pages/authentication/ResetPassword";
import ProtectedLoyout from "../layout/ProtectedLoyout";
import { useState } from "react";
import MyProfile from "../pages/myprofile/MyProfile";
import Disclaimer from "../components/footer/component/Disclaimer";

const Routing = () => {
  const [active, setActive] = useState('');

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout active={active} setActive={setActive} />}>
            <Route path="/services" element={<Services />} />
            <Route path="/services/:id" element={<Services />} />
            <Route index element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/signup-request" element={<AdvisorLogin />} />
            <Route path="/privacy-policy" element={<Disclaimer />} />
            <Route element={<ProtectedLoyout />}>
              <Route path="/marketgenius" element={<AdvisorList />} />
              <Route path="/marketgenius/view/:id" element={<ViewAdvisor />} />
              <Route path="/geniustalk" element={<Tips />} />
              <Route path="/my-profile" element={<MyProfile />} />
              <Route path="/privacy-policy" element={<Disclaimer />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verification" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Routing;
