import ViewRating from "../ViewRating";
import checkIcon from '../../../assets/images/svgicons/check-icon.svg';
import { useEffect } from "react";
import { gsap } from 'gsap';

interface props {
  title: string;
  description: string;
  imagePath: string;
  sebiNumber: string;
  reviewRating: number
}

const ViewAdvisorBanner = (props: props) => {
  useEffect(() => {
    // GSAP Animation
    const tl = gsap.timeline();
    tl.from('.text-container', {
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.3,
    });

  }, []);
  const { title, description, imagePath, sebiNumber, reviewRating } = props;
  return (
    <div className="text-container view-advisor-banner">

      <div className="col-lg-3">
        <div className="image-wrapper">
          <div className="breathing-circle">
            {imagePath ? <img src={imagePath} alt="banner-image" /> :
              <span className="name">{title?.charAt(0)}</span>}
          </div>
        </div>
      </div>
      <div className="col-lg-9">
        <div className="text-wrapper">
          <div className="view-advisor">
            <div className="advisor-info">
              <h1 className="title">{title}</h1>
              <div className="rating-grp">
                <ViewRating rating={reviewRating} />
                <h6 className="d-flex align-items-center"><img src={checkIcon} alt="checkIcon" className="me-2 pe-1" /><span>{sebiNumber}</span></h6>
              </div>
              <div className="view-description">
                <p className="description p-0">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAdvisorBanner;
