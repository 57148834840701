import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProfileImage, updateProfile, updateProfileImage, updateProfilePassword } from "../../helpers/backend_helper";
import toast from "react-hot-toast";

export const profileUpdateThunk = createAsyncThunk(
    "profileUpdate/profileUpdateThunk",
    async (data: any) => {
        const { payload, updateToken } = data;
        const response = await updateProfile(payload);
        if (response?.status?.toLowerCase() === "success") {
            toast.success(response?.message);
            updateToken(response?.token)
        } else {
            toast.error(response?.message);
        }
        return await response;
    }
);

//Change Password

export const changePasswordThunk = createAsyncThunk(
    "changepassword/changepasswordThunk",
    async (data: any) => {
        const { payload } = data;
        const response = await updateProfilePassword(payload);
        if (response?.status?.toLowerCase() === "success") {
            toast.success(response?.message);

        } else {
            toast.error(response?.message);
        }
        return await response;
    }
);

//Update profile image
export const updateProfileImageThunk = createAsyncThunk(
    "profileImageUpdate/updateProfileImageThunk",
    async (data: any) => {
        const { payload, afterUpdateImage } = data;
        try {
            const response = await updateProfileImage(payload, { headers: { "Content-Type": "multipart/form-data" } });
            if (response?.status?.toLowerCase() === "success") {
                toast.success(response?.message);
                afterUpdateImage(response?.token);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    }
);

//Update profile image
export const getProfileImgThunk = createAsyncThunk(
    "getProfileImg/getProfileImgThunk",
    async (data: any) => {
        try {
            const response = await getProfileImage(data);
            return await response;
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    }
);