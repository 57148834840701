import { Outlet, useLocation } from "react-router-dom";
// import Header from "./Header";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useEffect } from "react";
// import Footer from "./Footer";

const Layout = ({ active, setActive }: any) => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <Header active={active} setActive={setActive} />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
