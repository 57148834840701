import React, { useEffect, useState } from "react";
// import { homeServices } from "../../../constants/data/HomeServices";
import plusIcon from "../../../assets/images/svgicons/icon-plus.svg";
import subtractionIcon from "../../../assets/images/svgicons/icon-sub.svg";
import faqImage from "../../../assets/images/svgimage/faq.svg";
import question from "../../../assets/images/svgicons/question.svg";
import ImageBlog from "../../../components/common/ImageBlog";
import AOS from "aos";
import NoDataFound from "../../../components/common/NoDataFound";

const FAQ = ({ faq }: any) => {


  const [activeIndex, setActiveIndex] = useState(0);
  const handleAccordionClick = (index: any) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="row w-100">
        <div className="col-lg-6" data-aos="fade-right">
          <ImageBlog
            imagePath={faqImage}
            layout={1}
            altName={"FAQ-image"}
            rippleIcon={question}
          />
        </div>

        <div className="col-lg-6" data-aos="fade-left">
          {faq?.length === 0 ? (<NoDataFound message={'No Faq data'} />) : (
            <div className="accordion-group">
              {faq?.map((item: any, index: any) => (
                <div
                  key={index}
                  className={`accordion-items ${activeIndex === index ? "open-item" : ""
                    }`}
                >
                  <div
                    className="accordion-header"
                    onClick={() => {
                      handleAccordionClick(index);
                    }}
                  >
                    {activeIndex === index ? (
                      <img src={subtractionIcon} alt="" />
                    ) : (
                      <img src={plusIcon} alt="" />
                    )}
                    <h5>{item?.question || ""}</h5>
                  </div>
                  {index === activeIndex && (
                    <div className="accordion-body">
                      <div className="content-body">
                        <p>
                          {" "}
                          {
                            item?.answer || ""
                          }
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

          )}

        </div>
      </div>
    </>
  );
};

export default FAQ;
