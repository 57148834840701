import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSignupRequest } from "../../helpers/backend_helper";
import toast from "react-hot-toast";

export const addSignupRequestThunk = createAsyncThunk(
    "addSignupRequest/addSignupRequestThunk",
    async (data: any) => {
        const { payload, successFunction } = data;
        const response = await addSignupRequest(payload, { headers: { "Content-Type": "multipart/form-data" } });
        if (response?.status?.toLowerCase() === "success") {
            successFunction();
            toast.success(response?.message);
        } else {
            toast.error(response?.message);
        }
        return await response;
    }
);