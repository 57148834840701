import React, { useState } from "react";
import like from "../../../assets/images/svgicons/like.svg";
import like_outline from "../../../assets/images/svgicons/like_outline.svg";
import update_batch from "../../../assets/images/svgimage/update_batch.svg"
import dislike from "../../../assets/images/svgicons/dislike.svg";
import dislike_fill from "../../../assets/images/svgicons/dislike_fill.svg";
import { tipsTypes } from "../../../types/tipsTypes";
import { useAppDispatch } from "../../../store/store";
import { updateLikeAndDislike } from "../../../store/asyncThunks/Tips";
import ViewTipsModel from "../modal/ViewTipsModel";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const TipsCard = ({ tips, listData, setListData, hideLikes, hideUpdateBatchImage, className }: tipsTypes) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const accessToken = localStorage.getItem('auth-user');

  const handleLikes = (value: any) => {
    if (!accessToken) {
      setShowLoginModal(true);
      return;
    }
    const data: any = {};
    data['tip'] = tips?._id;
    data['like'] = value;
    data['listData'] = listData || null;
    data['setListData'] = setListData || null;
    dispatch(updateLikeAndDislike(data));
  }

  const toggeShowModal = () => {
    setShowModal(!showModal);
  }

  const toggelLoginShowModal = () => {
    setShowLoginModal(!showLoginModal);
  }

  const navigateAfterLogin = () => {
    localStorage.clear();
    navigate('/login');
  }

  return (
    <>
      <div className={`tips-item ${className} ${tips?.tipStatus === "CLOSED" ? "closed-tip" : ""}`}>
        <div className="closed-text"><p>Closed</p></div>
        <div className="tips-item-header cursor-pointer" onClick={() => toggeShowModal()}>
          <div>
            <div className="cat-type">
              <p>
                {tips?.category?.label}{!hideLikes && <span className="text-capitalize">{tips?.subcategory?.label}</span>}
              </p>
              <p className="time">
                {tips?.description?.length > 0 && tips?.description[0]?.updatedAt ?
                  (moment().format('l') === moment(tips?.description[0]?.updatedAt).format('l') ?
                    moment(tips?.description[0]?.updatedAt).format('LT') : moment(tips?.description[0]?.updatedAt).format('lll'))
                  : ''}
              </p>
            </div>
            <div>
              <div className="badges">
                <div style={hideLikes ? { display: 'flex', gap: '10px', alignItems: 'center' } : {}}>
                  {hideLikes && <div className="tips-item p-0" style={{ minHeight: 0 }}>
                    <div className="cat-type">
                      <p><span className="m-0">{tips?.subcategory?.label}</span></p>
                    </div>
                  </div>}
                  <div className="typ-stus">
                    <p className={`${tips?.stockTip?.toLowerCase().replaceAll("_", "-")}-tip`}>{tips?.stockTip?.toLowerCase()?.replace(/_/g, ' ')}</p>
                  </div>
                </div>
                {tips?.description?.length > 1 && !hideUpdateBatchImage && (
                  <div className="updated">
                    <img style={{ width: "70px" }} src={update_batch} alt='profile' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="tip-cont cursor-pointer" onClick={() => toggeShowModal()}>
          <p>
            {tips?.description && tips?.description.length > 0 ? tips.description[0].description : ''}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center ">
          <div className="advisor-info">
            <div className="advisor-pic">
              {
                tips?.advisor?.profilePath ?
                  <img src={tips?.advisor?.profilePath} alt="profile-picture" /> :
                  <span>{tips?.advisor?.firstName?.charAt(0)}</span>
              }
            </div>
            <h6>{tips?.advisor?.firstName}</h6>
          </div>
          {!hideLikes &&
            <div className="likes">
              <ul className="d-flex align-items-center g-2">
                <li>
                  <img className="cursor-pointer" src={tips?.reacted == "YES" ? like : like_outline} alt="like" onClick={() => handleLikes(tips?.reacted == "YES" ? "NULL" : "YES")} />
                  {tips?.likesCount}
                </li>
                <li>
                  <img className="cursor-pointer" src={tips?.reacted == "NO" ? dislike_fill : dislike} alt="like" onClick={() => handleLikes(tips?.reacted == "NO" ? "NULL" : "NO")} />
                  {tips?.disLikesCount}
                </li>
              </ul>
            </div>
          }
        </div>
      </div>
      <ViewTipsModel modalTitle="View GeniusTalk" showModal={showModal} tipData={tips} toggle={toggeShowModal} key={`model`} size="md" />
      <ConfirmationModal
        showModal={showLoginModal}
        toggle={toggelLoginShowModal}
        modalTitle={'Login Required!!!'}
        description={'Kindly log in to access the exclusive privileges.'}
        primaryBtn={'Sign In'}
        primaryAction={navigateAfterLogin}
      />
    </>
  );
};

export default TipsCard;
