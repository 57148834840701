import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTips, updateLikesAndDislikeStatus } from "../../helpers/backend_helper";
import { getRecentTips } from "../../helpers/backend_helper";
import { getTipsByAdvisor } from "../../helpers/backend_helper";
import toast from "react-hot-toast";

export const getTipsThunk = createAsyncThunk(
    "getallTips/getTipsThunk",
    async (data: any) => {
        try {
            const { payload, setOpenFilter, currentPage, dataPerPage } = data
            const response = await getTips(payload, currentPage, dataPerPage);
            if (response?.status?.toLowerCase() === "success") {
                // setOpenFilter(false)
            } else {
                toast.error(response?.message)
            } return await response;
        } catch (error: any) {
            return await error;
        }
    }
);

export const getRecentTipsThunk = createAsyncThunk(
    "getRecentTips/getRecentTipsThunk",
    async (limit: number) => {
        const response = await getRecentTips(limit);
        return await response;
    }
);

export const getAdvisorTipsThunk = createAsyncThunk(
    "getAdvisorTips",
    async (data: any) => {
        const response = await getTipsByAdvisor(data);
        return await response;
    }
);

export const updateLikeAndDislike = createAsyncThunk(
    "likeAndDislike",
    async (data: any) => {
        const response = await updateLikesAndDislikeStatus({ like: data?.like, tip: data?.tip });
        if (response?.status?.toLowerCase() !== "success") {
            toast.error(response?.message)
        } else {
            if (data?.listData) {
                data?.listData?.length > 0 && data?.setListData(
                    [...data?.listData].map(object => {
                        if (object._id === data?.tip) {
                            return {
                                ...object,
                                disLikesCount: response?.data?.likeData?.disLikesCount,
                                likesCount: response?.data?.likeData?.likesCount,
                                reacted: response?.data?.likeData?.reacted,
                            }
                        }
                        else return object;
                    }))
            }
        }
        return await response;
    }
);