import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import { useAppDispatch } from "../../store/store";
import { emailVerifiyThunk, loginThunk, resentVerifyThunk, signupThunk } from "../../store/asyncThunks/auth";
import { useNavigate } from "react-router";
import Applogo from '../../assets/images/white-logo.svg';
import { NavLink } from "react-router-dom";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import loginImage from '../../assets/images/login-image.png';
import pswShow from '../../assets/images/svgicons/eye.svg';
import pswHide from '../../assets/images/svgicons/eye-off.svg';
import ConfirmationModal from "../../components/common/modal/ConfirmationModal";
import { useSelector } from "react-redux";
import SpinnerBtn from "../../components/common/buttons/SpinnerBtn";

const EmailVerification = () => {

    document.title = "Email Verification | FiDeal";

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [watchFields, setWatchFields] = useState<any>();
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    const decodeduserEmail: any = localStorage.getItem("signup-site");
    const userEmail = atob(decodeduserEmail);

    const loading = useSelector((state: any) => state?.emailVerifiyReducer?.loading)

    const emailVerifyForm = {
        verificationToken: "",

    }
    const emailVerifySchema = Yup.object().shape({
        verificationToken: Yup.string().required("Enter verification code").max(6, 'verification code should not exceed 4 characters'),
    });
    const { register, handleSubmit, formState: { errors, }, watch } = useForm({
        defaultValues: emailVerifyForm,
        resolver: yupResolver(emailVerifySchema),
    });

    const navigateAfterLogin = () => {
        navigate("/login");
    };

    const onSubmit = (values: any) => {
        const data: any = {};
        data["values"] = values;
        data["navigate"] = toogleModal;
        dispatch(emailVerifiyThunk(data));
    };

    const toogleModal = () => {
        setShowModal(!showModal);
    };


    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setWatchFields(value);
            if (value?.verificationToken?.length === 4) {
                const data: any = {};
                data["value"] = value;
                data["navigate"] = toogleModal;
                dispatch(emailVerifiyThunk(data));
            }
        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);
    const resendEmailOTP = () => {
        const data: any = {};
        data["values"] = { email: userEmail };
        data["navigate"] = '';
        setMinutes(1);
        setSeconds(30);
        dispatch(resentVerifyThunk(data))
    };

    return (
        <div className="auth-screen">
            <div className="auth-overlayshape"></div>
            <div className="app-logo">
                <NavLink to={'/'}> <img src={Applogo} alt="Applogo" /></NavLink>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <div className="cont-bg">
                        <div className="ripple-small"></div>
                        <figure>
                            <img src={loginImage} alt="lgin " />
                        </figure>
                        <div className="ripple-small right"></div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="auth-form">
                        <div className="welcome-cont">
                            <h2>Email Verification</h2>
                            <p>We sent OTP verification code to your email given. Please check your email and enter the OTP that your received.</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className='inpt-item mb-4'>
                                <input type='tel' id='verificationToken' {...register("verificationToken")} maxLength={4} className={`${errors.verificationToken?.message ? 'is-invalid ' : ''} form-control ${watchFields?.verificationToken?.length > 0 ? "valid-field" : ""}`} />
                                <label htmlFor='verificationToken' className='inpt-label'>Enter Verification Code</label>
                                {errors.verificationToken?.message ? <span className={`invalid-feedback`} >{errors.verificationToken?.message}</span> : null}
                                <div className="countdown-text">
                                    {seconds > 0 || minutes > 0 ? (
                                        <p className="f-size-12 timing">Time remaining: <span className="remaining-time">{minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}</span>
                                        </p>
                                    ) : (
                                        <p className="f-size-12 timing">Didn't receive code?</p>
                                    )}

                                    <button className="border-0 bg-transparent p-0 resend-code"
                                        disabled={seconds > 0 || minutes > 0}
                                        style={{
                                            color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#DF0C00",
                                        }}
                                        onClick={resendEmailOTP}
                                    >
                                        Resend code
                                    </button>
                                </div>
                            </div>
                            <div className="auth-btn spinner-btn mt-0">
                                {loading ? <SpinnerBtn /> :

                                    ''}
                            </div>
                            {/* <div className="auth-btn">
                                {loading ? <SpinnerBtn /> :
                                    <PrimaryButton btnName="Verify OTP" btnType="submit" />}
                            </div> */}
                        </form>
                        <div className="back-to">
                            <p>Already have an account? <NavLink to={'/login'}>Sign In</NavLink></p>
                        </div>


                    </div>
                </div>
            </div>

            <ConfirmationModal
                showModal={showModal}
                toggle={toogleModal}
                modalTitle={'Sign In Required!!!'}
                description={'Email Verification was successful. Please click the sign in button below.'}
                primaryBtn={'Sign In'}
                primaryAction={navigateAfterLogin}

            />
        </div>

    );
};

export default EmailVerification;
